import { Injectable } from '@angular/core';
// import { CONF } from '../../tema';
import { TemplateService } from './template.service';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    constructor(private template: TemplateService){}

    esmx = {
        "Futebol": "Futbol",
        "Consultar Bilhete": "Consultar Boleto",
        "JB": "Lotto",
        "APOSTA": "BOLETO",
        "Aposta": "Boleto",
        "Data": "Fecha",
        "DATA": "FECHA",
        "APOSTADO": "MONTO",
        "PRÊMIO": "PREMIO",
        "Buscar": "Busca",
        "Click aqui para adicionar mais um campo!": "Haga clic aquí para agregar otro campo!",
        "BUSCAR": "BUSCA",
        "CÓDIGO": "COD.",
        "Código": "COD.",
        "EVENTOS": "PARTIDOS",
        "Ponto": "PV",
        "Meus Créditos": "Mis Creditos",
        "Ticket Crédito": "Credito",
        "Finalizar": "Fin",
        "Palpit.in": "All Play",
        "Feita em": "Generado en",
        "Todas as datas": "Todas las fechas",
        "Nome de usuário": "Nombre",
        "Senha": "Contraseña",
        "Nenhuma partida encontrada!": "No se encontraron eventos!",
        "Cadastrar": "Guardar",
        "Já tenho conta": "Ya tengo una cuenta",
        "Repita a senha": "Repetir Contraseña",
        "Nome de usuário (único)": "Nombre de usuario (login)",
        "Nome completo": "Nombre completo",
        "Criar conta": "Nuevo usuario",
        "Data e Horário": "Hora y Fecha",
        "Horário": "Hora",
        "Limpar": "Limpiar",
        "Voltar": "Volver",
        "Selecionados": "Seleccionados",
        "Prêmio": "Sorteo",
        "Carregando mais eventos": "Cargando más eventos",
        "Bilhete não encontrado!": "Boleto no encontrado!",
        "Casa": "Local",
        "CASA": "Local",
        "VALOR": "MONTO",
        "Fora": "Visitante",
        "FORA": "Visitante",
        "Segunda-Feira": "Lunes",
        "Terça-Feira": "Martes",
        "Quarta-Feira": "Miércoles",
        "Quinta-Feira": "Jueves",
        "Sexta-Feira": "Viernes",
        "Pagar com cambista": "Terminar",
        "Finalizando...": "Terminando...",
        "validação": "validacion",
        "Nova Aposta": "Nuevo ticket",
        "Aposta Finalizada com sucesso!": "¡Apuesta terminada con éxito!",
        "Agora é só enviar o código abaixo para um cambista! Boa Sorte!": "¡Ahora envíe el código a continuación a un punto de venda! ¡Buena suerte!",
    }

    idiomas: any = [{}, this.esmx]
    idioma_padrao = this.template.getSite().idioma ? this.template.getSite().idioma : 0

    translate(text: any) {
        // console.log(text)
        if (this.idiomas[this.idioma_padrao]) {
            if (!this.idiomas[this.idioma_padrao][text] || this.idioma_padrao == 0) return text
            if (this.idiomas[this.idioma_padrao][text]) return this.idiomas[this.idioma_padrao][text]
        } else {
            return text
        }
    }
}
