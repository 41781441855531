<div style="height: 100%; overflow: auto;">
    <div class="col-md-12 pb-2 pt-3 d-flex paddingOnMobile" style="overflow: auto;">
        <button (click)="getResult({day: 2});" class="btn btn-sm btnLeft">
            <i class="fas fa-chevron-left"></i>
        </button>
        <button class="btn btn-sm btnCenter" *ngIf="maisDatas == true" style="display: flex" (click)="maisDatas = false">
                <div *ngFor="let d of days; index as indice">
                    <div class="maisDatas" (click)="getResult({date: d}); dayIndex = indice">
                        {{d | date: " dd/MM/yyyy"}}
                    </div>
                </div>
        </button>
        <button class="btn btn-sm btnCenter" *ngIf="maisDatas == false" (click)="maisDatas = true">
            <div>{{days[dayIndex] | date: "dd/MM/yyyy"}}</div>
        </button>
        <button (click)="getResult({day: 1})" class="btn btn-sm btnRight">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
    <div class="col-md-12">
        <div>
            <div style="border-radius: 20px;">
                <div class="tableLiga" *ngFor="let ligas of api.resultados">
                    <div class="campeonato">{{ligas.titulo}}</div>
                    <div class="col-md-12 d-flex rowJogo" *ngFor="let jogo of ligas.jogos">
                        <div class="colJogo">{{jogo.data | date: "hh:mm a"}}</div>
                        <div class="colJogo timeCasa">{{jogo.casa}}</div>
                        <div class="colJogo text-center">{{jogo.resultado.placar_casa + " - " +
                            jogo.resultado.placar_fora}}</div>
                        <div class="colJogo">{{jogo.fora}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="semResultados" *ngIf="api.resultados.length == 0">
        <div style="text-align: center;">
            <div><i style="font-size: 80pt;" class="fas fa-exclamation-triangle"></i></div>
            Opa... <br>
            Sem resultados
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>