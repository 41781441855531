<div style="height: 100%; overflow:hidden;" *ngIf="!creditoHistorico && !api.apostasUsuario">
    <div class="row col-md-12 p-0">
        <div class="col-md-8 p-0 pb-2 d-flex paddingOnMobile">
            <input [(ngModel)]="api.inputAddCredito" type="text" #busca class="form-control form-sm" placeholder="Adicionar Crédito" mask='AAAA-AAAA'>
            <button type="submit" class="btn btn-success btn-sm pl-3 pr-3" (click)="api.addCreditoConta()">
                <i class="fas fa-plus"></i>
            </button>
        </div>
        <div class="col-md-4 p-0 pb-2 pl-3 paddingOnMobile">
            <button class="btn btn-outline-light btn-sm w-100 h-100" style="letter-spacing: 2px;font-size: 14pt; font-weight: bold;" (click)="api.getBilhetes()">
                Bilhetes
            </button>
        </div>
    </div>
    <div class="col-md-12">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">TOKEN</th>
                        <th scope="col">GERADO</th>
                        <th scope="col">VALOR</th>
                        <th scope="col">SALDO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr" *ngFor="let item of api.creditos" (click)="getHistorico(item)">
                        <td scope="row" class="tdResult">
                            {{item.token}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.createdAt | date: "dd/MM/yyyy HH:mm"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.valor | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.saldo | currency: "BRL"}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div style="height: 100%; overflow:hidden;" *ngIf="creditoHistorico">
    <div class="row">
        <div class="col p-0 d-flex justify-content-between align-items-center" [ngClass]="{'justify-content-end': !token}">
            <div style="font-size: 15pt;" class="paddingOnMobile" *ngIf="token">
                Histórico do crédito: {{token || ''}}
            </div>
            <div class="botao-volta pl-5 pr-2" (click)="clearHistorico()">
                <i class="fas fa-long-arrow-alt-left" style="font-size: 30pt;"></i>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="overflow: auto;">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">COD.</th>
                        <th scope="col">VALOR</th>
                        <th scope="col">PRÊMIO</th>
                        <th scope="col">FEITA</th>
                        <th scope="col">STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr" *ngFor="let item of historico" (click)="getApostaModal(item.aposta._id)">
                        <td scope="row" class="tdResult">
                            {{item.aposta.cod}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.aposta.valor_bruto | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.aposta.premios | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.aposta.createdAt | date: "dd/MM/yyyy HH:mm"}}
                        </td>
                        <td scope="row" class="tdResult">
                            <b *ngIf="item.aposta.status == 1">Válida</b>
                            <b *ngIf="item.aposta.status != 1">Inválida</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
<div style="height: 100%; overflow:hidden;" *ngIf="api.apostasUsuario">
    <div class="row">
        <div class="col p-0 d-flex justify-content-end">
            <div class="botao-volta pl-5 pr-2" (click)="api.apostasUsuario = null">
                <i class="fas fa-long-arrow-alt-left" style="font-size: 30pt;"></i>
            </div>
        </div>
    </div>
    <div class="col-md-12 " style="overflow: auto; height: 100%;">
        <div style="border-radius: 20px;">
            <table class="tableLiga mb-5">
                <thead>
                    <tr>
                        <th scope="col">Cod.</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Prêmio</th>
                        <th scope="col">Feita</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr" *ngFor="let item of api.apostasUsuario" (click)="getApostaModal(item.cod)">
                        <td scope="row" class="tdResult">
                            {{item.cod}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.valor | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.premios | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.criado | date: "dd/MM/yyyy HH:mm"}}
                        </td>
                        <td scope="row" class="tdResult">
                            <b *ngIf="item.status == 1">Válida</b>
                            <b *ngIf="item.status != 1" style="color: red;">Inválida</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<view-aposta></view-aposta>