<div class="col-md-12 p-0" style="border-bottom-right-radius: 20px; margin: 0px; overflow:scroll; height: 100%;">
    <div class="col-md-12 p-0">
        <table class="table table-hover">
            <tbody>
                <!-- <tr>
                    <td id="tdBody" scope="row">
                        <div class="td tdBody">
                            <div class="row">
                                <div class="col-1 m-0 p-0" style="align-self: center;">
                                    <img src="../../../assets/globe_branco.png" alt="" width="30" height="30">
                                </div>
                                <div class="col ml-2" style="align-self: center;">
                                    PARTIDAS EM DESTAQUE
                                </div>
                            </div>
                        </div>
                        <div class="td tdBody sub">
                            <div class="row">
                                <div class="col-1 m-0 p-0" style="align-self: center;">
                                    <img src="../../../assets/trophy_branco1.png" alt="" width="30" height="30">
                                </div>
                                <div class="col ml-2" style="align-self: center; text-overflow: ellipsis;">
                                    CORINTHIANS X SÃO PAULO
                                </div>
                            </div>
                        </div>
                        <div class="td tdBody sub">
                            <div class="row">
                                <div class="col-1 m-0 p-0" style="align-self: center;">
                                    <img src="../../../assets/trophy_branco1.png" alt="" width="30" height="30">
                                </div>
                                <div class="col ml-2" style="align-self: center; text-overflow: ellipsis;">
                                    <div class="row">
                                        <div class="col text-center">PAYSANDU</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-center">X</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-center">REMO</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr> -->
                <tr>
                    <td id="tdHeader" scope="row">
                        <div class="td tdHeaderWide w-100" (click)="api.getJogosAovivo(); api.showAoVivo = true" *ngIf="api.partidas_aovivo">
                            <div class="row">
                                <div class="col-1 m-0 p-0" style="align-self: center;">
                                    <img src="../../../assets/live_branco.png" alt="" width="30" height="30">
                                </div>
                                <div class="col ml-2 pr-0" style="align-self: center;">
                                    AO VIVO
                                    <span class="live_count">
                                        {{api.partidas_aovivo}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="td tdHeaderWide w-100">
                                <div class="row">
                                    <div class="col-1 m-0 p-0" style="align-self: center;">
                                        <img src="../../../assets/arrow_top.png" alt="" width="30" height="30">
                                    </div>
                                    <div class="col ml-3 mr-0 pr-0" style="align-self: center;">
                                        MAIS APOSTADOS
                                    </div>
                                </div>
                            </div>
                            <div class="divider w-100 mw-100"></div> -->
                            <div class="td tdHeaderWide" style="width: 85%;" (click)="todosCampeonatos(); api.showAoVivo = false">
                                TODOS CAMPEONATOS
                            </div>
                            <div class="td tdHeaderMobile" (click)="dropList()" style="width: 85%;" #ligasHtml>
                                {{titulo}}
                            </div>
                            <div style="width: 15%;" class="d-flex justify-content-end pr-4">
                                <button style="height: 65%; min-width: 32px;"
                                class="btn btn-sm btn-outline-primary align-self-center btn-trophy"
                                (click)="dropList()">
                                <i class="fas fa-trophy" *ngIf="expandMenu == false || !expandMenu"></i>
                                <i class="fas fa-times" *ngIf="expandMenu == true"></i>
                            </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <div [ngClass]=" expandMenu == true ? 'ligasMenuMobile' : 'ligas' ">
                    <tr *ngIf="expandMenu && titulo !== 'TODOS CAMPEONATOS'">
                        <td id="tdBody" scope="row">
                            <div class="td tdBody" (click)="todosCampeonatos(); api.showAoVivo = false">
                                TODOS CAMPEONATOS
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let country of api.countrys" (click)="titulo = country.regiao; api.showAoVivo = false">
                        <td id="tdBody" scope="row">
                            <div class="td tdBody" (click)="tournament({pais: country.regiao}); api.showAoVivo = false">
                                <div class="row">
                                    <div class="col-1 m-0 p-0" style="align-self: center;">
                                        <img src="../../../assets/globe_branco.png" alt="" width="30" height="30">
                                    </div>
                                    <div class="col ml-2" style="align-self: center;">
                                        {{country.regiao}}
                                    </div>
                                </div>
                            </div>
                            <div class="td tdBody sub" (click)="tournament({liga: campeonato._id}); api.showAoVivo = false" *ngFor="let campeonato of country.campeonatos">
                                <div class="row">
                                    <div class="col-1 m-0 p-0" style="align-self: center;">
                                        <img src="../../../assets/trophy_branco1.png" alt="" width="30" height="30">
                                    </div>
                                    <div class="col ml-2" style="align-self: center; text-overflow: ellipsis;">
                                        {{campeonato.titulo}}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td id="tdLaster" scope="row" (click)="goTop()">
                            <div class="td tdLaster">
                                Ir para o topo
                            </div>
                        </td>
                    </tr>
                </div>
            </tbody>
        </table>
    </div>
</div>
<app-loading [loading]="loading"></app-loading>