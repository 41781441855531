import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { ApiService } from 'src/app/service/api.service';
import { JbService } from 'src/app/service/jb.service';
import { TemplateService } from 'src/app/service/template.service';
import { TranslatorService } from 'src/app/service/translator.service';

@Component({
  selector: 'app-finaliza-jb',
  templateUrl: './finaliza-jb.component.html',
  styleUrls: ['./finaliza-jb.component.scss']
})
export class FinalizaJbComponent implements OnInit {
  dataAposta: any
  show_form_credito: boolean = false;
  constructor(public template: TemplateService, public api: ApiService, public jbService: JbService, public translator: TranslatorService) { }

  ngOnInit(){
    setInterval(()=>{
      this.dataAposta = dayjs()
    }, 1000)
  }
  
  async liberaFormCredito(){
    try {
      await this.api.meusCreditos();
      this.show_form_credito = true
    } catch (error) {
      this.show_form_credito = true
      this.api.creditos = []
      console.log(error)
    }
  }

}
