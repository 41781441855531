import { TemplateService } from "src/app/service/template.service";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as dayjs from "dayjs";
import { ApiService } from "src/app/service/api.service";
import { decode } from "punycode";
import { UtilService } from "src/app/service/util.service";
import { JbService } from "src/app/service/jb.service";
import { NotificacaoService } from "src/app/service/notificacao.service";

@Component({
  selector: "menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @ViewChild("searchMobile") searchMobile!: ElementRef;
  styleSearchMenu = false;
  countrys: any;
  loading: any = false;
  toogleMenu = false;
  wideOptions = false;
  showLogin = false;
  isJb = false;
  wideScreen = false;
  datas: any = [];
  showDrop = false;
  showResultJB = false;
  showDropRes = false;
  maisDatas = false
  i_data: any = 0;
  // resultado = {
  //   jb: false
  // }
  dataResultado: any = dayjs().format("YYYY-MM-DD")

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?: any) {
    if (window.innerWidth < 750) {
      this.wideScreen = false;
    } else {
      this.wideScreen = true;
    }
    console.log(this.wideScreen);
  }

  form: FormGroup;
  constructor(
    public api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public template: TemplateService,
    public util: UtilService,
    private jbService: JbService,
    private notify: NotificacaoService,
    public actroute: ActivatedRoute
  ) {
    this.form = this.fb.group({
      busca: this.fb.control("", [Validators.minLength(4)]),
    });
  }

  async ngOnInit() {
    if (window.location.pathname) this.isJb = true;
    console.log(window.location.pathname);
    this.getScreenSize();
    await this.changeData();
    this.actroute.queryParamMap.subscribe(async queryParams => {
      let busca = queryParams.get('codBusca');
      if (busca){
        this.api.cod_busca = busca
        this.api.getAposta()
      }
  });
  }

  goTo(onde = "") {
    console.log("GO TO: ", this.template.getSite().title)
    if (this.template.getSite().title != 'Red Lottery'){
      this.router.navigate([onde]);
      this.wideOptions = false;
      this.toogleMenu = false;
    }
  }

  async reset() {
    await this.api.getCampeonatos({ reset: 2 });
  }

  async buscar(busca = "") {
    console.log(this.form.getError);
    this.loading = true;
    this.goTo("inicio");
    try {
      let form = this.form.getRawValue();
      await this.api.getCampeonatos({ busca: form.busca, reset: 1 });
      this.styleSearchMenu = false;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  drop(dropWhat: Number) {
    // this.wideOptions = false
    if (dropWhat == 0)
      this.styleSearchMenu = !this.styleSearchMenu,
        (this.toogleMenu = false);
    if (dropWhat == 1)
      this.toogleMenu = !this.toogleMenu,
        (this.styleSearchMenu = false);
    if (dropWhat == 2)
      this.wideOptions = !this.wideOptions
    if (dropWhat == 3)
      this.showLogin = !this.showLogin, (this.toogleMenu = false);
    if(dropWhat == 4)
      this.showResultJB = !this.showResultJB, (this.toogleMenu = false);
  }

  async changeData() {
    // this.i_data = i;
    if (this.dataResultado > dayjs().format("YYYY-MM-DD")) {
      this.dataResultado = dayjs().format("YYYY-MM-DD")
      return this.notify.erroRest("Você não pode avançar no tempo!")
    }
    this.maisDatas = false;
    this.api.dataBusca = dayjs(this.dataResultado).toDate().toISOString();
    await this.api.getSorteios();
  }

  getImage(bicho: any){
    return this.jbService.animais.find(b => b.numero == bicho.grupo)
  }

  // geraDatas() {
  //   for (let i = 0; i <= 5; i++) {
  //     let data = new Date();
  //     let d = new Date(data.setDate(data.getDate() - i));
  //     this.datas.push({
  //       iso: d.toISOString(),
  //       show: `${this.util
  //         .getTextDateTimeUsToBR(d)
  //         .substr(0, 5)} - ${this.util.diaSemana(d).substr(0, 3)}`,
  //     });
  //   }
  // }

  check(){
    console.log(dayjs(this.dataResultado).toDate().toISOString())
  }
}
