import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public show = false;
  public showAposta = false;
  public altura = 0;
  constructor() { }

  clickMenu(){
    if (window.matchMedia("(max-width: 991px)").matches) {
      this.show = !this.show;
    }
  }

  

  checkMenu(){
    this.altura = window.innerHeight;
    if(window.innerWidth < 768){
      this.show = false;
      this.showAposta = false;
    }
    else {
      this.show = true;
      this.showAposta = true;
    }
  }
}
