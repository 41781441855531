import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
// @ts-ignore
import * as faker from 'faker'
import { ApiService } from 'src/app/service/api.service';


@Component({
  selector: 'app-left-web-menu',
  templateUrl: './left-web-menu.component.html',
  styleUrls: ['./left-web-menu.component.scss']
})
export class LeftWebMenuComponent implements OnInit{
  expandMenu: any = false
  titulo:any = "TODOS CAMPEONATOS"
  loading = false
  campeonatos: any
  countrys: any
  

  constructor(public api: ApiService, private router: Router) {
  }
  ngOnInit() {
    this.loading = true
    this.api.countrysSideBar()
    this.api.getCampeonatos({}).then(()=>{
      this.loading = false
    })
    console.log(this.api.valores())
  }


  async tournament({liga = '' , pais = ''}) {
    this.loading = true
    this.expandMenu = false
    await this.api.getCampeonatos({busca: pais, campeonato: liga, reset: 1})
    this.goTo('inicio')
    this.loading = false
  }


  dropList() {
    this.expandMenu ? this.expandMenu = false : this.expandMenu = true
  }

  goTop() {
    window.scrollTo(0, 0)
  }

  goTo(onde = ""){
    this.router.navigate([onde])
  }

  async todosCampeonatos() {
    this.loading = true
    this.expandMenu = false
    this.titulo = "TODOS CAMPEONATOS"
    this.api.campeonatos = []
    await this.api.getCampeonatos({reset: 2})
    this.loading = false
    this.goTo('inicio')
  }
}