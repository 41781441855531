import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import {
  Event,
  NavigationEnd,
  NavigationStart,
  ResolveStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { InicioComponent } from "./components/home/inicio/inicio.component";
import { ApiService } from "./service/api.service";
import { TemplateService } from "./service/template.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private titleService: Title,
    public template: TemplateService,
    private router: Router,
    private api: ApiService
  ) {
    this.router.events
    .pipe(filter((event: Event) => event instanceof NavigationStart))
    .subscribe((v: any) => {
      let media: any = document.querySelector('body')?.clientWidth
      media >= 750 ? media = 0 : media = 1
      if (v.url === "/jb") this.isJB = true;
      else this.isJB = false;
      this.api.isJb = this.isJB
      if (v.url === "/politicas/privacidade") this.show = false;
      setTimeout(() => {
        this.template.setColorTheme(this.isJB, media)
      }, 500)
    });
    
    let config = this.template.getSite();
    this.titleService.setTitle(config.title);
    document
      .getElementById("favicon-img")
      ?.setAttribute("href", config.favicon);
    if (config.title == "Red Lottery"){
      this.router.navigate(["/jb"])
    }
  }
  title = "Palpitin";
  isJB = false
  show = true
}
