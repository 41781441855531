import { ApiService } from 'src/app/service/api.service';
import { Component, Input, OnInit, Output, EventEmitter, HostListener } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
// import * as EventEmitter from "events";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @Input("showLogin") showLogin = false;
  @Output() estado = new EventEmitter()

  showCadastrar = false;
  login: FormGroup
  cadastrar: FormGroup
  avisos:any = []

  constructor(private fb: FormBuilder, private api: ApiService) {
    this.login = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      senha: this.fb.control('', [Validators.required])
    })
    this.cadastrar = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      username: this.fb.control('', [Validators.required]),
      senha: this.fb.control('', [Validators.required]),
      senha2: this.fb.control('', [Validators.required]),
    })
  }

  ngOnInit(){
    
  }


  async clickNovaConta() {
    this.api.loading = true;
    this.avisos = []
    try {
      let values = this.cadastrar.value;
      if (values.senha.length < 4) {
        this.avisos.push({
          titulo: 'Dados necessários',
          texto: 'A senha deve ter no minimo 4 caracteres.'
        })
        throw 1;
      }
      if (values.senha != values.senha2) {
        this.avisos.push({
          titulo: 'Dados necessários',
          texto: 'A senhas não são iguais.'
        })
        throw 1;
      }
      let data = await this.api.post(`/site/novaconta`, values);
      this.api.setLogado(data);
      this.showLogin = false
      this.showCadastrar = false
    } catch (error) {
      console.log(error);
    }
    this.api.loading = false;
  }


  async clickLogin(){
    this.api.loading = true
    this.showLogin = false
    try {
      let values = this.login.value
      let dados:any = await this.api.login(values)
      if (dados.token) this.api.setLogado(dados)
      else throw "Login não autorizado."
      console.log("DADOS LOGADO: ", dados)
    } catch (error) {
      this.showLogin = true
      console.log(error)
    }
    this.api.loading = false
  }

  check() {
    console.log("aqui");
  }

  show(showWhat: Number) {
    if (showWhat == 0)
      this.showCadastrar ? (this.showCadastrar = false) : (this.showCadastrar = true);
    if (showWhat == 1)
      this.showLogin ? (this.estado.emit(false), this.showLogin = false): (this.estado.emit(true), this.showLogin = true);
  }

}
