import { TemplateService } from 'src/app/service/template.service';
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  isDevMode,
} from "@angular/core";
import { ApiService } from "src/app/service/api.service";
// import { html2canvas } from "html2canvas";
// declare let html2canvas: any;
import html2canvas from "html2canvas";
// import html2canvas from "../../../../../node_modules/html2canvas/dist/types"

@Component({
  selector: "view-aposta",
  templateUrl: "./view-aposta.component.html",
  styleUrls: ["./view-aposta.component.scss"],
})
export class ViewApostaComponent implements OnInit {
  @ViewChild("bilhete") bilhete!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public api: ApiService,
    public template: TemplateService
  ) {}

  ngOnInit() {}

  // async printBilhete() {
  //   let bilhete = this.bilhete.nativeElement.innerHTML;
  //   let original = this.document.body.innerHTML;
  //   var meuBilhete = document.createElement("canvas");
  //   meuBilhete.innerHTML = bilhete.trim();
  //   this.document.body.innerHTML = meuBilhete
  //   let canvas = await html2canvas(meuBilhete);
  //   console.log(canvas)
  //   this.document.body.innerHTML = original;
  // }
}
