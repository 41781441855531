import { Component, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/service/template.service';

@Component({
  selector: 'app-footer-template',
  templateUrl: './footer-template.component.html',
  styleUrls: ['./footer-template.component.scss']
})
export class FooterTemplateComponent implements OnInit {

  constructor(public template: TemplateService) { }

  ngOnInit(): void {

  }

}
