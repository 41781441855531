<div class="finalizaBG" *ngIf="openFinaliza && !api.apostaRecebida">
    <div class="col-md-12 p-0 finaliza">
        <div class="d-flex justify-content-between mt-3 pr-2 pl-2" (click)="openFinaliza = false">
            <button class="btn btn-danger float-center mb-1" (click)="limparTicket()">
                <h5>Limpar Ticket</h5>
            </button>
            <i class="fas fa-times" style="font-size: 30pt;"></i>
        </div>
        <div class="d-flex justify-content-around w-100 detalheHeader">
            <div class="col-md-6  align-self-center p-0">
                <div class="row d-flex justify-content-center align-items-center">
                    <h5 class="m-0 row"><i class="fas fa-tasks mr-1 mt-1"></i> Apostas:</h5>
                    <h6>{{api.jogosApostados.length}}</h6>
                </div>
            </div>
            <div class="col-md-6 align-self-center p-0">
                <div class="row d-flex justify-content-center">
                    <h5>Taxas:</h5><h6 style="color: #eeff01; font-size: 11pt; margin: 2px 5px; align-self:flex-end;">{{api.valores().toFixed(2)}}</h6>
                </div>
            </div>
        </div>
        <hr>
        <div class="col-md-12 mt-4" [formGroup]="aposta">
            <div class="d-flex justify-content-between col-md-12">
                <div class="col-md p-0 inputGroup mr-1">
                    <div class="divCCifrao m-0">R$</div>
                    <input type="text" class="inputValorApostado" min="1" [(ngModel)]="api.valor_aposta" formControlName="valorApostado" (keyup)="valorPremio()" currencyMask>
                </div>
                <div class="col-md p-0 inputGroup">
                    <div class="divCCifrao m-0 p-0 text-center" style="justify-content: center; align-items: center;">
                        <i class="fas fa-hand-holding-usd text-center"></i>
                    </div>
                    <input type="text" class="inputValorApostado" [value]="api.getPremioFormatado()" [disabled]="true">
                </div>
            </div>
            <div class="d-flex justify-content-between col-md-12 mt-1" *ngIf="template.getSite().inserir_nome_cliente">
                <div class="col-md p-0 inputGroup mr-1">
                    <div class="divCCifrao m-0 p-0 text-center" style="justify-content: center; align-items: center;">
                        <i class="fas fa-user  text-center"></i>
                    </div>
                    <input type="text" class="inputValorApostado" [(ngModel)]="api.nome_cliente" formControlName="nome_cliente" placeholder="Nome">
                </div>
                <div class="col-md p-0 inputGroup">
                    <div class="divCCifrao m-0 p-0 text-center" style="justify-content: center; align-items: center;">
                        <i class="fas fa-phone  text-center"></i>
                    </div>
                    <input type="text" class="inputValorApostado" [(ngModel)]="api.telefone_cliente" formControlName="telefone_cliente" placeholder="Telefone" mask='(00)00000-0000'>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-4 pr-4 pl-4" *ngIf="!show_form_credito && api.jogosApostados.length > 1 && api.valor_aposta >= api.valor_minimo">
            <div class="col-md-12" [ngClass]="{'regras': api.regras.mostraModal && api.jogosApostados.length > 1 && api.valor_aposta >= api.valor_minimo}">
                <div class="col-md-12 caixa" *ngIf="api.regras.mostraModal && api.jogosApostados.length && api.valor_aposta >= api.valor_minimo">
                    <div class="d-flex justify-content-between" style="z-index: 10;">
                        <p>Li e concordo</p>
                        <div class="checkbox" [ngClass]="{check: api.regras.leu}" (click)="api.regras.leu = !api.regras.leu">
                            <i class="fas fa-check"></i>
                        </div>
                        <!-- <div style="height: 20px; width: 20px; background-color: white; border-radius: 3px;" (click)="regras.leu = false"></div> -->
                    </div>
                    <p class="m-0">Observações:</p>
                    <p>
                        Não pagaremos: Já realizados, por erro de hora ou jogos antecipados. No caso de casadinhas, serão
                        calculados
                        os valores, sendo pago apenas os jogos realizados. Aposta válida por 8 dias. Será pago em até 48h.
                        Verifique
                        a taxa no momento de sua aposta.
                        Se o jogo não acontecer no prazo de 24h da data inicial, suas apostas serão recalculadas.
                        Todos os jogos são definidos ao final de 90min, incluindo acrescimos definidos pelo arbitro. Não são
                        contabilizados: prorrogações, gol de honra ou disputa de penaltis.
                    </p>
                </div>
            </div>
            <button class="btn btn-dark w-100 mb-1" [disabled]="api.jogosApostados.length < 2 || api.valor_aposta < api.valor_minimo || !api.regras.leu" (click)="api.finalizaAposta(1);">
                <h5 *ngIf="api.getSessao()?.ponto">Finalizar</h5>
                <h5 *ngIf="!api.getSessao()?.ponto">Finalizar com cambista</h5>
            </button>
            <button class="btn btn-dark w-100 mb-1" [disabled]="api.jogosApostados.length < 2 || api.valor_aposta < api.valor_minimo || !api.regras.leu" (click)="liberaFormCredito()" *ngIf="api.getSessao() && !api.getSessao().ponto">
                <h5>Pagar com crédito</h5>
            </button>
        </div>
        <div class="col-md-12 mt-4 pr-4 pl-4" *ngIf="show_form_credito && api.jogosApostados.length > 1 && api.valor_aposta >= api.valor_minimo">
            <select class="form-control mb-1" [(ngModel)]="api.credito_selecionado">
                <option [value]="null">Selecione o crédito</option>
                <option [value]="item._id" *ngFor="let item of api.creditos">{{item.token}} - Saldo: {{item.saldo | currency: "BRL"}}</option>
            </select>
            <button class="btn btn-dark w-100 mb-1" [disabled]="aposta.invalid" (click)="api.finalizaAposta(3)">
                <h5>Pagar com crédito</h5>
            </button>
            <button class="btn btn-dark w-100 mb-1" (click)="api.credito_selecionado = null; show_form_credito = false">
                <h5>Voltar</h5>
            </button>
        </div>
        <div class="col-md-12 text-center mt-4" *ngIf="apostaGerada">
            <h5>Cod. de validação</h5>
            <img class="mt-4" [src]="codValidacao">
        </div>
        <!-- <div class="col-md-12 mt-4 pr-4 pl-4" *ngIf="api.jogosApostados.length">
            
        </div> -->
        <div class="mt-3">
            <div style="border-radius: 20px;">
                <table class="tableLiga mt-3" *ngFor="let jogo of api.jogosApostados">
                    <tbody>
                        <tr>
                            <td scope="row" class="d-flex justify-content-between">
                                <div class="col-10">
                                    <div>
                                        {{jogo.casa}} x {{jogo.fora}}
                                    </div>
                                    <div>
                                        {{jogo.data | date: 'MMM d, y, h:mm a'}}
                                    </div>
                                    <div>
                                        <h6>
                                            {{jogo.cotacoes.sigla[1].toUpperCase()}}
                                        </h6>
                                    </div>
                                    <div>
                                        <h6>
                                            Aposta: {{jogo.cotacoes.sigla[2].toUpperCase()}}
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div style="right: 0; top: 0; position: absolute; cursor: pointer; width: 100%; text-align: end;"
                                        (click)="api.removeAposta(jogo._id)">
                                        <h6>x</h6>
                                    </div>
                                    <h6 style="right: 0; bottom: 0; position: absolute;">
                                        {{jogo.cotacoes.valor.toFixed(2)}}</h6>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="finalizaBG" *ngIf="api.apostaRecebida">
    <div class="finaliza mt-2 pb-3 pt-3">
        <div class="row">
            <div class="col">
                <h2>Aposta Finalizada com sucesso!</h2>
                <h3 style="color: #eeff01">{{api.apostaRecebida.cod}}</h3>
                <p *ngIf='api.apostaRecebida.status != 1'>Agora é só enviar o código abaixo para um cambista! Boa Sorte!</p>
                <p *ngIf='api.apostaRecebida.status == 1 && !this.api.getSessao()?.ponto'>A sua aposta foi paga com seu crédito e já é valida! Boa Sorte!</p>
            </div>
        </div>
        <div class="col-md-12 text-center mt-4 mb-4" *ngIf='api.apostaRecebida.status != 1'>
            <h5>Cod. de validação</h5>
            <div class="row">
                <div class="col">
                    <h2 style="color: #eeff01">{{api.apostaRecebida.cod_validacao}}</h2>
                </div>
            </div>
            <img class="mt-4" [src]="api.apostaRecebida.qr">
        </div>
        <div class="row">
            <div class="col text-center">
                <button class="btn btn-dark w-100 mb-1" (click)="show_form_credito = false; api.apostaRecebida = null; openFinaliza = false">
                    <h5>Nova Aposta</h5>
                </button>
            </div>
        </div>
        <div class="row" *ngIf="api.getSessao()?.ponto">
            <div class="col text-center">
                <a [href]="api.apostaRecebida.link" target="_blank">
                    <button class="btn w-100 mb-1" style="background-color: #128c7e !important; color: white !important;">
                        <h5>Enviar WhatsApp</h5>
                    </button>
                </a>
            </div>
        </div>
        <div class="row" *ngIf="api.getSessao()?.ponto && api.apostaRecebida.link_consulta" (click)="copyText(api.apostaRecebida.link_consulta)">
            <div class="col text-center">
              <span>Link para consulta: <b>{{api.apostaRecebida.link_consulta}}</b></span>
            </div>
        </div>
        <div class="regras">
            <div class="col-md-12 caixa">
                <p class="m-0">Observacoes:</p>
                <p>
                    Não pagaremos: Já realizados, por erro de hora ou jogos antecipados. No caso de casadinhas, serão
                    calculados
                    os valores, sendo pago apenas os jogos realizados. Aposta válida por 8 dias. Será pago em até 48h.
                    Verifique
                    a taxa no momento de sua aposta
                    Se o jogo não acontecer no prazo de 24h da data inicial, suas apostas serão recalculadas.
                    Todos os jogos são definidos ao final de 90min, incluindo acrescimos definidos pelo arbitro. Não sendo
                    contabilizados: prorrogações, gol de honra ou disputa de penaltis.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-finaliza" *ngIf="api.jogosApostados.length > 0 && !openFinaliza">
    <span>
        <div class="colored-box-finaliza row" (click)="openFinaliza = true">
          <div class="col text-center" style="display: flex; align-items: center;">
            SEU CUPOM <span class="badge bg-danger text-white pl-2 pr-2 ml-2">{{api.jogosApostados.length}}</span>
          </div>
        </div>
    </span>
</div>

<app-loading [loading]='api.loading'></app-loading>