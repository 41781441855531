<app-loading [loading]='loading || api.loading'></app-loading>
<div class="col-md-12 p-0 d-flex justify-content-between align-items-center" id="menuWeb">
    <div class="ml-4 wideConfig" *ngIf="api.isJb && !template.getSite().estadium">
        <h5 class="d-flex align-items-center jb fut mr-2" (click)="goTo('inicio')" *ngIf="template.getSite().title != 'Red Lottery' ">
            Jogar Futebol
        </h5>
    </div>
    <div class="col-md-6 row p-0" id="menu" *ngIf="!api.isJb">
        <div class="col-md-4 d-flex search" [formGroup]="form">
            <input type="text" class="form-control" formControlName="busca" (keyup.enter)="buscar()"
                placeholder="Buscar no site">
            <button class="btn btn-outline-primary" (click)="buscar()">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div class="col-md-4 d-flex search">
            <input [(ngModel)]="api.cod_busca" type="text" class="form-control" (keyup.enter)="api.getAposta()"
                placeholder="Buscar aposta">
            <button class="btn btn-outline-primary" (click)="api.getAposta()" [disabled]="loading || api.loading">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div class="erro" style="overflow: visible;">
            <p *ngIf="api.erroBusca" style="margin: 0px;"><b>Aposta não encontrada.</b></p>
        </div>
    </div>
    <div class="row" style="align-items: center;">
        <!-- wide menu -->
        <h5 class="d-flex align-items-center wideConfig jb mr-1"
            *ngIf="!wideOptions && !api.isJb && !template.getSite().estadium && template.getSite().jb" (click)="goTo('jb')">
            Jogar JB
        </h5>
        <h5 class="d-flex align-items-center menu" *ngIf="!wideOptions" (click)="drop(2)">
            Menu <b *ngIf="api.getSessao()">&nbsp;- {{api.getSessao().username}}</b>
        </h5>
        
        <i class="d-flex align-items-center menu fas fa-chevron-right" *ngIf="wideOptions" (click)="drop(2)"></i>
        <!-- Opcoes wide menu -->
        <div class="d-flex align-items-center" *ngIf="wideOptions">
            <h5 class="menuOpcoes" (click)="goTo('inicio')">
                Inicio
            </h5>
            <h5 class="menuOpcoes" (click)="goTo('meus-creditos')" *ngIf="api.getSessao() && !template.getSite().estadium">
                Meus Créditos
            </h5>
            <h5 class="menuOpcoes" *ngIf="!api.isJb" (click)="goTo('resultados')">
                Resultados
            </h5>
            <h5 class="menuOpcoes" *ngIf="!api.isJb && api.getSessao()?.ponto" (click)="goTo('vendas')">
                Minhas Vendas
            </h5>
            <h5 class="menuOpcoes" *ngIf="api.isJb" (click)="drop(4)">
                JB - Resultados
            </h5>
            <h5 class="menuOpcoes" (click)="goTo('jb')" *ngIf="!template.getSite().estadium && !api.isJb && template.getSite().jb">
                JB
            </h5>
            <h5 class="menuOpcoes" (click)="drop(3)" *ngIf="!api.getSessao()">
                Entrar
            </h5>
            <h5 class="menuOpcoes" *ngIf="api.getSessao()" (click)="api.logoff()">
                Sair <b *ngIf="api.getSessao()">&nbsp;-&nbsp; {{api.getSessao().username.toUpperCase()}}</b>
            </h5>
        </div>
        <!-- mobile btn menu -->
        <i class="d-flex align-items-center menuMobile fas fa-ellipsis-v" (click)="drop(1)"></i>
        <!-- logo -->
        <span class="mobile-none">|</span>
        <img class="mr-2 ml-2" [ngClass]="template.getSite().classicon" [src]="template.getSite().url_logo"
             (click)="api.mostraMaisCotacoes = false; reset(); goTo('inicio')">
        <p *ngIf="api.getSessao()" class="menuMobile" style="font-size: 12pt; margin: 0;">
            - {{api.getSessao().username}}
        </p>
    </div>
    <!-- search mobile -->
    <div class="mobileBarRight displayMobile pr-2 pl-2" *ngIf="!wideScreen">
        <div class="jbm mr-3" *ngIf="!template.getSite().estadium && !api.isJb && template.getSite().jb" (click)="goTo('jb')">
            Jogar JB
        </div>
        <div>
            <button class="btn btn-sm btn-primary" (click)="drop(0)" style="min-width: 32px;">
                <i class="fas fa-times" *ngIf="styleSearchMenu"></i>
                <i class="fas fa-search" *ngIf="!styleSearchMenu"></i>
            </button>
        </div>
    </div>
    <div class="displayMobile mr-2" *ngIf="!wideScreen && api.isJb && !template.getSite().estadium && this.template.getSite().title != 'Red Lottery'">
        <h5 class="d-flex align-items-center jb fut mr-2" *ngIf="!wideOptions" (click)="goTo('inicio')">
            Jogar Futebol
        </h5>
    </div>
</div>

<div *ngIf="styleSearchMenu && !api.isJb" class="displayMobile">
    <div class="col-md-12 p-0 border">
        <div class="col-md-2 pb-2 d-flex" [formGroup]="form">
            <input type="text" #busca class="form-control form-sm" formControlName="busca"
                (keyup.enter)="buscar(busca.value)" placeholder="Buscar no site">
            <button type="submit" (click)="buscar(busca.value)" class="btn btn-success btn-sm">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div class="col-md-2 pb-3 d-flex">
            <input type="text" [(ngModel)]="api.cod_busca" class="form-control form-sm" (keyup.enter)="api.getAposta()"
                placeholder="Buscar aposta">
            <button class="btn btn-success btn-sm" (click)="api.getAposta()">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div class="erro text-center pb-2" style="overflow: visible;">
            <p *ngIf="api.erroBusca" style="margin: 0px;"><b>Aposta não encontrada.</b></p>
        </div>
    </div>
</div>

<div class="displayMobile" *ngIf="toogleMenu">
    <div class="col-md-12 p-0 border" style="min-height: 40vh;">
        <div class="col-md opcoes" (click)="goTo('inicio')">
            <i class="fas fa-home mr-2"></i> Inicio
        </div>
        <div class="col-md opcoes" *ngIf="api.getSessao() && !template.getSite().estadium" (click)="goTo('meus-creditos')">
            <i class="fas fa-wallet mr-2"></i> Meus Créditos
        </div>
        <div class="col-md opcoes" *ngIf="!api.isJb" (click)="goTo('resultados')">
            <i class="fas fa-futbol mr-2"></i> Resultados
        </div>
        <div class="col-md opcoes" *ngIf="!api.isJb && api.getSessao()?.ponto" (click)="goTo('vendas')">
            <i class="fas fa-futbol mr-2"></i> Minhas Vendas
        </div>
        <div class="col-md opcoes" (click)="goTo('jb')" *ngIf="!template.getSite().estadium && !api.isJb && template.getSite().jb">
            <i class="fas fa-dog mr-2"></i> JB
        </div>
        <div class="col-md opcoes" (click)="drop(4)" *ngIf="!template.getSite().estadium && api.isJb">
             <i class="fas fa-dog mr-2"></i> JB - Resultados
        </div>
        <div class="col-md opcoes" *ngIf="!api.getSessao()" (click)="drop(3)">
            <i class="fas fa-sign-in-alt mr-2"></i> Entrar
        </div>
        <div class="col-md opcoes" *ngIf="api.getSessao()" (click)="api.logoff(); drop(1)">
            <i class="fas fa-sign-out-alt mr-2"> </i> Sair
        </div>
        <div class="row p-3 mt-2" (click)="drop(1)">
            <div class="col text-center">
                <i class="fas fa-times p-1"></i>
            </div>
        </div>
    </div>
</div>
<login [showLogin]="showLogin" (estado)="showLogin = $event"></login>
<view-aposta></view-aposta>

<div class="containerBG" *ngIf="showResultJB && api.isJb">
    <div (click)="showResultJB = false" class="closeX">
        <i class="fa fa-times"></i>
    </div>
    <div class="containerModal p-2">
        <div class="d-flex justify-content-center">
            <input type="date" class="btn btnCenter" [(ngModel)]="dataResultado" (change)="changeData()" name="" id="">
        </div>
        <div>
            <div class="flex-container column center col-md-12">
                <div class="flex-item text-center caixaResultado" *ngFor="let item of api.sorteios">
                    <hr>
                    <h5 class="text-center">{{item.ext}}</h5>
                    <hr>
                    <table class="w-100">
                        <tr *ngFor="let dado of item.dados">
                            <td class="text-left">
                                {{dado.premio}}º
                            </td>
                            <td text-center>
                                {{dado.milhar}}
                            </td>
                            <td class="text-center">
                                {{dado.grupo}}
                            </td>
                            <td class="text-right">
                                {{dado.bicho}}
                            </td>
                            <td class="text-right">
                                <!-- <img style="width: 20px; height: auto" [src]="getImage(dado)?.imgPng" alt=""> -->
                                <i [ngClass]="getImage(dado)?.icone || ''" style="color: white; font-size: 23px;"></i>
                            </td>
                        </tr>
                    </table>
                    <!-- <div class="space"></div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Botão data -->
<!-- <button (click)="changeData((i_data + 1 > 5) ? 5 : i_data + 1)" class="btn btn-sm btnLeft">
                <i  *ngIf="i_data < 5"  class="fas fa-chevron-left p-0"></i>
            </button> -->
<!-- <button class="btn btn-sm btnCenter" *ngIf="!maisDatas" (click)="maisDatas = true">
                <div>{{datas[i_data]?.show}}</div>
            </button>
            <div [ngClass]="(maisDatas) ? 'maisDatas' : 'd-none'">
                <button class="btn btn-sm btnCenter mb-2 mt-1" (click)="changeData(i)"
                    *ngFor="let data of datas; let i = index">
                    <div>
                        {{data?.show}}
                    </div>
                </button>
            </div> -->
<!-- <button  (click)="changeData((i_data - 1 < 0) ? 0 : i_data - 1)" class="btn btn-sm btnRight">
                <i *ngIf="i_data > 0" class="fas fa-chevron-right p-0"></i>
            </button> -->