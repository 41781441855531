<div class="containerBG" *ngIf="api.aposta_busca">
    <div class="col-md-4 containerModal">
        <div class="closeX" (click)="api.aposta_busca = null; api.cod_busca = '' ">
            <i class="fas fa-times" style="font-size: 30pt;"></i>
        </div>
        <div #bilhete data-html2canvas-ignore="true">
            <h4 class="bancaSite">{{template.getSite().titulo}}</h4>
            <h4>COD.: {{api.aposta_busca.cod}}</h4>
            <h4>DATA: {{api.aposta_busca.data | date: 'dd/MM/yyyy'}}</h4>
            <h4 *ngIf="api.aposta_busca.horario != 100">HORARIO: {{api.aposta_busca.horario_sigla}}</h4>
            <p>Feita em: {{api.aposta_busca.data_hora_string}}</p>
            <div style="display: flex;" *ngIf="api.aposta_busca.operador">
                <p>Ponto:</p>
                <p *ngIf="api.aposta_busca.operador"> {{api.aposta_busca.operador.ponto.cod + " - " +
                    api.aposta_busca.operador.ponto.nome}}</p>
            </div>
            <p *ngIf="api.aposta_busca.info_cliente">
                <span *ngIf="api.aposta_busca.info_cliente.nome">Cliente: {{api.aposta_busca.info_cliente.nome}}</span>
            </p>
            <p *ngIf="api.aposta_busca.info_cliente">
                <span *ngIf="api.aposta_busca.info_cliente.telefone">Telefone: {{api.aposta_busca.info_cliente.telefone}}</span>
            </p>
            <p *ngIf="api.aposta_busca.status == 1">Situação do bilhete: <b>Válido</b></p>
            <p *ngIf="api.aposta_busca.status == 3">Situação do bilhete: <b>Inválido</b></p>
            <p *ngIf="api.aposta_busca.status == 0 || api.aposta_busca.status == 2">Situação do bilhete: <b style="color: red;">Cancelado</b></p>
            <p *ngIf="(api.aposta_busca.status == 0 || api.aposta_busca.status == 2) && api.aposta_busca.hora_cancelado">Cancelamento feito em: <b class="borda_texto" style="color: red;">{{api.aposta_busca.hora_cancelado | date: 'dd/MM/yyyy HH:mm'}}</b></p>
            <hr style="margin: 5px 0px;">
            <div class="jb" *ngIf="api.aposta_busca.horario != 100">
                <h4 class="bancaSite">
                    JOGOS
                </h4>
                <hr style="margin: 5px 0px;">
                <div class="mt-3 mb-3">
                    <div style="border-radius: 20px;">
                        <table class="tableLiga mb-3">
                            <thead>
                                <tr>
                                    <th>TIPO</th>
                                    <th>JOGO</th>
                                    <th>PREMIO</th>
                                    <th>VALOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let jogo of api.aposta_busca.jogos">
                                    <td scope="row" class="text-center">
                                        {{jogo.siglas.join(" ")}}
                                    </td>
                                    <td scope="row" class="text-center" style="word-wrap: break-word !important; max-width: 100px;">
                                        {{jogo.dados.join(" ")}}
                                    </td>
                                    <td scope="row" class="text-center">
                                        {{jogo.premios.join(",")}}
                                    </td>
                                    <td scope="row" class="text-center">
                                        {{jogo.valor | currency: 'BRL'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="futebol" *ngIf="api.aposta_busca.horario == 100">
                <h4 class="bancaSite">
                    EVENTOS
                </h4>
                <hr style="margin: 5px 0px;">
                <div class="mt-3 mb-3">
                    <div style="border-radius: 20px;">
                        <table class="tableLiga mb-3" *ngFor="let jogo of api.aposta_busca.eventos">
                            <tbody>
                                <tr>
                                    <td scope="row" class="d-flex justify-content-between">
                                        <div class="col-10 p-0">
                                            <div style="text-align: center;">
                                                {{jogo.casa + " " + jogo.placar_casa + " x " + jogo.placar_fora + " " + jogo.fora}}
                                            </div>
                                            <div>
                                                {{jogo.data_hora | date: 'MMM d, y, HH:mm'}}
                                            </div>
                                            <div>
                                                <p>{{jogo.cotacao.sigla.split(";")[1].toUpperCase()}}</p>
                                                <p>{{jogo.cotacao.sigla.split(";")[2].toUpperCase()}}</p>
                                            </div>
                                        </div>
                                        <div class="col-2 d-flex justify-content-center align-items-center">
                                            <div class="time">
                                                <i *ngIf="!jogo.apurado && !jogo.cancelado" class="fas fa-hourglass" style="color: var(--corFontPrincipal)"></i>
                                                <i *ngIf="jogo.apurado && jogo.cotacao.acerto" class="far fa-check-circle"
                                                    style="color: var(--corOuro)"></i>
                                                <i *ngIf="jogo.apurado && !jogo.cotacao.acerto" class="far fa-times-circle"
                                                    style="color: red"></i>
                                                <i *ngIf="!jogo.apurado && jogo.cancelado" class="fas fa-ban"
                                                    style="color: red"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr style="margin: 5px 0px;">
            <div class="mb-3">
                <h5>Valor Bruto: {{api.aposta_busca.valor_bruto | currency: "BRL"}}</h5>
                <h5 *ngIf="api.aposta_busca.horario == 100">Qtd. Jogos: {{api.aposta_busca.eventos.length}}</h5>
                <h5 *ngIf="api.aposta_busca.horario != 100">Qtd. Jogos: {{api.aposta_busca.jogos.length}}</h5>
                <h5 *ngIf="api.aposta_busca.premios == 0">P. Prêmio: {{api.aposta_busca.p_premio | currency: "BRL"}}</h5>
                <h5 *ngIf="api.aposta_busca.premios > 0">Prêmio: <span class="text-danger borda_texto">{{api.aposta_busca.premios | currency: "BRL"}}</span></h5>
            </div>
        </div>
    </div>
</div>