import { TemplateService } from 'src/app/service/template.service';
import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import { InicioComponent } from "src/app/components/home/inicio/inicio.component";
import * as faker from "faker";

@Component({
  selector: "app-finaliza-jogo",
  templateUrl: "./finaliza-jogo.component.html",
  styleUrls: ["./finaliza-jogo.component.scss"],
})
export class FinalizaJogoComponent implements OnInit {
  loading = false;
  aposta: FormGroup;
  valorApostado: any = 0.0;
  jogosApostados: any = [];
  
  codValidacao: any;
  bilhete: any = {};
  apostaGerada = false;
  show_form_credito = false

  constructor(private fb: FormBuilder, public api: ApiService, public template: TemplateService) {
    this.aposta = this.fb.group({
      valorApostado: this.fb.control("", [Validators.required]),
      nome_cliente: this.fb.control(""),
      telefone_cliente: this.fb.control("")
    });
  }

  valorPremio() {
    let valorApostado = this.aposta.get("valorApostado");
    this.valorApostado = valorApostado?.value;
  }

  copyText(val:any) {
    console.log("copyed")
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert(`Link ${val} copiado.`)
  }

  limparTicket() {
    this.api.jogosApostados = []
    this.api.valor_aposta = 0
    this.show_form_credito = false
    this.api.apostaRecebida = null
  }

  async liberaFormCredito() {
    try {
      await this.api.meusCreditos();
      this.show_form_credito = true
    } catch (error) {
      this.show_form_credito = true
      this.api.creditos = []
      console.log(error)
    }
  }

  ngOnInit() {
    if (this.template.getSite().showRegas) this.api.regras.mostraModal = true
    if (!this.template.getSite().showRegas) this.api.regras.leu = true
  }

  valida(){
    console.log(this.aposta.invalid)
    if(this.aposta.invalid) console.log(this.aposta.invalid)
  }

  // async geraAposta() {
  //   this.loading = true;
  //   this.apostaGerada = true;
  //   let valorApostado = this.aposta.get("valorApostado")?.value;
  //   let cod = faker.random.alphaNumeric(8).toUpperCase();
  //   let taxa = this.api.valores()
  //   let bilheteAposta = {
  //     cod: cod,
  //     jogos: await this.api.jogosApostados,
  //     valorApostado: valorApostado,
  //     taxa: taxa.toFixed(2),
  //     valorPremio: (valorApostado * taxa).toFixed(2)
  //   }
  //   try {
  //     console.log(bilheteAposta)
  //     this.codValidacao = await this.api.generateQRCode(cod);
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   this.api.jogosApostados = [];
  //   this.loading = false;
  //   this.aposta.reset()
  // }

  teste() {
    console.log("aqui");
  }

  check(a: any) {
    console.log(a);
  }
}
