import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent implements OnInit {

  constructor(
    public api: ApiService,
    private router: Router,
  ) { }

  loadingHistorico = false
  historico:any = []
  creditoHistorico = null
  apostaModal = null
  showPule = false
  token: any = ""

  ngOnInit() {
    if (!this.api.getSessao())
      this.router.navigate(['/inicio']);
    this.api.meusCreditos();
  }

  async getHistorico(credito: any) {
    this.clearHistorico()
    this.api.loading = true
    try {
      let resp = await this.api.getHistoricoCredito(
        {
          id_credito: credito._id
        }
      )
      if (resp.historico) this.historico = resp.historico
      this.token = credito.token
      this.creditoHistorico = credito
    } catch (error) {
      console.log(error)
    }
    this.api.loading = false
  }

  getApostaModal(aposta: any) {
    this.api.cod_busca = aposta
    this.api.getAposta()
  }

  clearHistorico() {
    this.historico = []
    this.creditoHistorico = null
  }


}
