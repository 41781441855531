import { Component, OnInit } from "@angular/core";
import * as dayjs from "dayjs";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-resultados",
  templateUrl: "./resultados.component.html",
  styleUrls: ["./resultados.component.scss"],
})
export class ResultadosComponent implements OnInit {
  loading = false;
  days: any = [];
  dayIndex: any = 0;
  day: any = "";
  maisDatas = false;
  constructor(public api: ApiService) {}

  ngOnInit() {
    this.daysResult();
    this.getResult({});
  }

  daysResult() {
    for (let day = 0; day > -6; day--) {
      this.days.push(dayjs().add(day, "day").toISOString());
    }
  }

  async getResult({ day = 0, date = '' }) {
    this.loading = true;
    this.maisDatas = false
    try {
      if (day != 0) {
        if (day == 2 && this.days.length - 1 > this.dayIndex) {
          this.dayIndex++;
        }
        if (day == 1 && 0 < this.dayIndex) {
          this.dayIndex--;
        }
        await this.api.getResultados(this.days[this.dayIndex]);
      } else if (date !== "") {
        this.day = date
        await this.api.getResultados(this.day);
      } else {
        await this.api.getResultados(this.days[day])
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  check(a: any) {
    console.log(a);
  }
}
