import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor() { }

  getSite() {
    let host = window.location.hostname;
    // console.log("HOST =>>>>>>>   ", host)
    if (host.indexOf("penha") != -1) {
      return this.sites.penha;
    }
    if (host.indexOf("aposterio") != -1) {
      return this.sites.rio;
    }
    if (host.indexOf("aposte") != -1) {
      return this.sites.estadium;
    }
    if (host.indexOf("beta") != -1) {
      return this.sites.estadium;
    }
    if (host.indexOf("nortbets") != -1) {
      return this.sites.nortbets;
    }
    if (host.indexOf("orla") != -1) {
      return this.sites.orla;
    }
    if (host.indexOf("zs") != -1) {
      return this.sites.zs;
    }
    if (host.indexOf("crt") != -1) {
      return this.sites.crt;
    }
    if (host.indexOf("redlottery") != -1) {
      return this.sites.redLottery;
    }
    if (host.indexOf("esportesul") != -1) {
      return this.sites.esportesul;
    }
    if (host.indexOf("betfourbet") != -1) {
      return this.sites.betforbet;
    }
    return this.sites.palpitin;
  }

  setColorTheme(isJB: any = false, media = 0) {
    let config = this.getSite();
    let { cor1, cor2, cor3, cor4 }: any = config.cores;
    let url_logo: any = config.logo;
    let min: any = 1
    min = Math.ceil(min);
    let max: any = 25
    max = Math.floor(max);
    let animal = "--url_" + String(Math.floor(Math.random() * (max - min)) + min);
    console.log(animal)
    document.querySelector('body')?.style.setProperty('--cor1', `${cor1}`);
    document.querySelector('body')?.style.setProperty('--cor2', `${cor2}`);
    document.querySelector('body')?.style.setProperty('--cor3', `${cor3}`);
    document.querySelector('body')?.style.setProperty('--cor4', `${cor4}`);
    document.querySelector('body')?.style.setProperty('--corGradientCircle', `radial-gradient(at top right, ${cor4} 10%, ${cor3} 20%, ${cor2} 70%)`);
    document.querySelector('body')?.style.setProperty('--corGradientFooter', `linear-gradient(135deg, ${cor4} , ${cor4} , ${cor1})`);
    if (!isJB) {
      document.querySelector('body')?.style.setProperty('background', `var(${url_logo}) no-repeat right top fixed, var(--corGradientCircle), no-repeat`);
    } else {
      if (this.getSite().title == 'Red Lottery') return;
      if (media == 0) {
        document.querySelector('body')?.style.setProperty('background', `var(${animal}) no-repeat left bottom -10vh fixed, var(--corGradientCircle), no-repeat`);
      } else if (media == 1) {
        document.querySelector('body')?.style.setProperty('background', `var(${animal}) no-repeat left -60vw bottom fixed, var(--corGradientCircle), no-repeat`);
      }
    }
  }

  public sites = {
    palpitin: {
      logoloading: null,
      logo: "--url_bola",
      url_logo: "../../../../assets/palpit.png",
      favicon: "../../../assets/img/logo2019.svg",
      title: "Palpit.in - O seu palpit de sorte!",
      titulo: "Palpit.in",
      rodape: "Palpit.in",
      showRegas: false,
      cores: {
        cor1: `#0b4b20`,
        cor2: `#1b7f43`,
        cor3: `#18af54`,
        cor4: `#0fb84f`
      },
      matriz: "5cd8fe1a3578410012ba0a3c",
      idioma: 0,
      classicon: "logo",
      creditos: true,
      estadium: false,
      jb: true,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {}
        if (labels[label]) return labels[label]
        else return label
      }
    },
    redLottery: {
      logoloading: null,
      logo: "--url_bola",
      url_logo: "../../../../assets/redlottery.jpg",
      favicon: "../../../assets/img/redlottery.jpg",
      title: "Red Lottery",
      titulo: "Red Lottery",
      rodape: "Red Lottery",
      showRegas: false,
      cores: {
        cor1: `#CE0C24`,
        cor2: `rgb(73, 0, 0)`,
        cor3: `rgb(155, 0, 0)`,
        cor4: `rgb(155, 0, 0)`,
      },
      matriz: "6191d9beabc6703eb2f66649",
      idioma: 1,
      classicon: "logo-redlottery",
      creditos: true,
      estadium: false,
      jb: true,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {
          "Futebol": "Futbol",
    "Consultar Bilhete": "Consultar Boleto",
    "JB": "Lotto",
    "APOSTA": "BOLETO",
    "Aposta": "Boleto",
    "Data": "Fecha",
    "DATA": "FECHA",
    "APOSTADO": "MONTO",
    "PRÊMIO": "PREMIO",
    "Buscar": "Busca",
    "Click aqui para adicionar mais um campo!": "Haga clic aquí para agregar otro campo!",
    "BUSCAR": "BUSCA",
    "CÓDIGO": "COD.",
    "Código": "COD.",
    "EVENTOS": "PARTIDOS",
    "Ponto": "PV",
    "JOGOS": "JUEGOS",
    "Meus Créditos": "Mis Creditos",
    "Ticket Crédito": "Credito",
    "Finalizar": "Fin",
    "Palpit.in": "All Play",
    "Feita em": "Generado en",
    "Todas as datas": "Todas las fechas",
    "Nome de usuário": "Nombre",
    "Senha": "Contraseña",
    "Nenhuma partida encontrada!": "No se encontraron eventos!",
    "Cadastrar": "Guardar",
    "Já tenho conta": "Ya tengo una cuenta",
    "Repita a senha": "Repetir Contraseña",
    "Nome de usuário (único)": "Nombre de usuario (login)",
    "Nome completo": "Nombre completo",
    "Criar conta": "Nuevo usuario",
    "Data e Horário": "Hora y Fecha",
    "Horário": "Hora",
    "Limpar": "Limpiar",
    "Voltar": "Volver",
    "Selecionados": "Seleccionados",
    "Prêmio": "Sorteo",
    "Carregando mais eventos": "Cargando más eventos",
    "Bilhete não encontrado!": "Boleto no encontrado!",
    "Casa": "Local",
    "CASA": "Local",
    "VALOR": "MONTO",
    "Fora": "Visitante",
    "FORA": "Visitante",
    "Segunda-Feira": "Lunes",
    "Terça-Feira": "Martes",
    "Quarta-Feira": "Miércoles",
    "Quinta-Feira": "Jueves",
    "Sexta-Feira": "Viernes"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    },
    orla: {
      logoloading: null,
      url_logo: "../../../../assets/palpit.png",
      logo: "--url_bola",
      favicon: "../../../assets/img/logo2019.svg",
      title: "Palpit.in - O seu palpit de sorte!",
      titulo: "Palpit.in",
      rodape: "Palpit.in",
      showRegas: true,
      cores: {
        cor1: `#0b4b20`,
        cor2: `#1b7f43`,
        cor3: `#18af54`,
        cor4: `#0fb84f`
      },
      matriz: "5dc0b9966c908f0013006c35",
      idioma: 0,
      classicon: "logo",
      creditos: true,
      estadium: false,
      jb: false,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {}
        if (labels[label]) return labels[label]
        else return label
      }
    },
    betforbet: {
      logoloading: null,
      url_logo: "../../../../assets/betforbet4.png",
      logo: "--url_bola",
      favicon: "../../../../app/assets/favicon-betforbet2.png",
      title: "Bet Four Bet",
      titulo: "Bet Four Bet",
      rodape: "Bet Four Bet",
      showRegas: true,
      cores: {
        cor1: `#0b4b20`,
        cor2: `#1b7f43`,
        cor3: `#18af54`,
        cor4: `#0fb84f`
      },
      matriz: "64d51fa2f4577352da2a03ac",
      idioma: 0,
      classicon: "logo-betforbet",
      creditos: true,
      estadium: false,
      jb: false,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {}
        if (labels[label]) return labels[label]
        else return label
      }
    },
    zs: {
      logoloading: null,
      url_logo: "../../../../assets/palpit.png",
      logo: "--url_bola",
      favicon: "../../../assets/img/logo2019.svg",
      title: "Palpit.in - O seu palpit de sorte!",
      titulo: "Palpit.in",
      rodape: "Palpit.in",
      showRegas: true,
      cores: {
        cor1: `#0b4b20`,
        cor2: `#1b7f43`,
        cor3: `#18af54`,
        cor4: `#0fb84f`
      },
      matriz: "5e29cdc3590ed80012d68170",
      idioma: 0,
      classicon: "logo",
      creditos: true,
      estadium: false,
      jb: false,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {}
        if (labels[label]) return labels[label]
        else return label
      }
    },
    crt: {
      logoloading: null,
      url_logo: "../../../../assets/palpit.png",
      logo: "--url_bola",
      favicon: "../../../assets/img/logo2019.svg",
      title: "Palpit.in - O seu palpit de sorte!",
      titulo: "Palpit.in",
      rodape: "Palpit.in",
      showRegas: true,
      cores: {
        cor1: `#0b4b20`,
        cor2: `#1b7f43`,
        cor3: `#18af54`,
        cor4: `#0fb84f`
      },
      matriz: "5f037b57bfab860012b6b06c",
      idioma: 0,
      classicon: "logo",
      creditos: true,
      estadium: false,
      jb: false,
      hash_numero: false,
      inserir_nome_cliente: false,
      getLabel: (label: any) => {
        let labels: any = {}
        if (labels[label]) return labels[label]
        else return label
      }
    },
    estadium: {
      logoloading: null,
      logo: "--url_est",
      url_logo: "../../assets/estadium-grande-branco.svg",
      favicon: "favestadium.ico",
      title: "Estadium",
      rodape: "Estadium",
      showRegas: false,
      cores: {
        cor1: `#CE0C24`,
        cor2: `rgb(73, 0, 0)`,
        cor3: `rgb(155, 0, 0)`,
        cor4: `rgb(155, 0, 0)`,
      },
      estadium: true,
      hash_numero: false,
      inserir_nome_cliente: true,
      jb: false,
      creditos: false,
      classicon: "logo_estadium",
      titulo: "Estadium",
      idioma: 0,
      matriz: "5f0b148a6c7472001a0f5f9b",
      getLabel: (label: any) => {
        let labels: any = {
          "Exemplo": "Exemplo"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    },
    esportesul: {
      logoloading: "../../assets/logoebranco.png",
      logo: "",
      url_logo: "../../assets/logoesportesul.png",
      favicon: "favestadium.ico",
      title: "ESPORTE SUL",
      rodape: "ESPORTE SUL",
      showRegas: false,
      cores: {
        cor1: `#133133`,
        cor2: `#35AA71`,
        cor3: `#133133`,
        cor4: `#133133`,
      },
      estadium: true,
      hash_numero: false,
      inserir_nome_cliente: true,
      jb: false,
      creditos: false,
      classicon: "logo_esportesul",
      titulo: "ESPORTE SUL",
      idioma: 0,
      matriz: "5f0b148a6c7472001a0f5f9b",
      getLabel: (label: any) => {
        let labels: any = {
          "Exemplo": "Exemplo"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    },
    rio: {
      logoloading: null,
      logo: "--url_est",
      url_logo: "../../assets/estadium-grande-branco.svg",
      favicon: "favestadium.ico",
      title: "Estadium",
      rodape: "Estadium",
      showRegas: false,
      jb: false,
      cores: {
        cor1: `#CE0C24`,
        cor2: `rgb(73, 0, 0)`,
        cor3: `rgb(155, 0, 0)`,
        cor4: `rgb(155, 0, 0)`,
      },
      estadium: true,
      hash_numero: false,
      inserir_nome_cliente: false,
      classicon: "logo logo_estadium",
      titulo: "Estadium",
      idioma: 0,
      creditos: false,
      matriz: "5f98851ecdd0ae00133d35cc",
      getLabel: (label: any) => {
        let labels: any = {
          "Exemplo": "Exemplo"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    },
    penha: {
      logoloading: null,
      url_logo: "../../../../assets/logo-estadium-branca.svg",
      logo: " ../../../assets/img/logoestadium.svg",
      favicon: "../../../assets/img/favestadium.ico",
      title: "Estadium",
      rodape: "Estadium",
      showRegas: false,
      cores: {
        corpadrao: `rgb(170, 39, 45)`,
        corpadrao2: `rgb(73, 0, 0)`,
        corsubpadrao: `rgb(155, 0, 0)`,
        corBotaoNormal: `rgb(255, 219, 219)`,
        corBotaoNormalHover: `rgb(241, 255, 219)`
      },
      hash_numero: false,
      estadium: true,
      creditos: false,
      jb: false,
      inserir_nome_cliente: false,
      classicon: "logo logo_estadium",
      titulo: "Estadium",
      idioma: 0,
      matriz: "600f0cbef6a4c8000a2799fd",
      getLabel: (label: any) => {
        let labels: any = {
          "Exemplo": "Exemplo"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    },
    nortbets: {
      logoloading: null,
      url_logo: "../../../../assets/logoarena.png",
      logo: "../../../assets/img/logoarena.png",
      favicon: "../../../assets/img/favarena.ico",
      title: "NORTBET$",
      rodape: "NORTBET$",
      showRegas: false,
      cores: {
        corpadrao: `rgb(219, 77, 33)`,
        corpadrao2: `rgb(180, 77, 33)`,
        corsubpadrao: `rgb(180, 77, 33)`,
        corBotaoNormal: `rgb(217, 221, 225)`,
        corBotaoNormalHover: `rgb(93, 162, 189)`
      },
      estadium: false,
      creditos: false,
      jb: false,
      hash_numero: true,
      inserir_nome_cliente: true,
      classicon: "logo logo_estadium",
      titulo: "NORTBET$",
      idioma: 0,
      matriz: "604ba2bac97e3d000b12d30a",
      getLabel: (label: any) => {
        let labels: any = {
          "Exemplo": "Exemplo"
        }
        if (labels[label]) return labels[label]
        else return label
      }
    }
  }
}



