import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as dayjs from "dayjs";
import { ApiService } from "src/app/service/api.service";
import { JbService } from "src/app/service/jb.service";
import { NotificacaoService } from "src/app/service/notificacao.service";
import { TemplateService } from "src/app/service/template.service";
import { TranslatorService } from "src/app/service/translator.service";
import { UtilService } from "src/app/service/util.service";

@Component({
  selector: "app-jb",
  templateUrl: "./jb.component.html",
  styleUrls: ["./jb.component.scss"],
})
export class JbComponent implements OnInit {
  days: any = [];
  form: any = {
    data_selecionada: 0,
    horario: "",
  };
  jbstep = 0;
  mostraModal = false
  datas: any = []
  showDrop = false
  showDropRes = false
  i_data: any = 0
  campoSelecionado: any = null

  constructor(
    private api: ApiService,
    public jbService: JbService,
    public translator: TranslatorService,
    public template: TemplateService,
    private notify: NotificacaoService,
    private router: Router,
    public util: UtilService
  ) {}

  async ngOnInit() {
    this.jbService.init();
    if (this.template.getSite().estadium) this.router.navigate(['/inicio'])
  }

  teste(){
    console.log(this.jbService.premios)
  }

  _mostraModal(i:any){
    this.campoSelecionado = i
    this.mostraModal = true
  }
  check(value: any) {
    console.log("@@@", value);
  }

  continue() {
    try {
      if (!this.form.horario) throw new Error("Por favor, informe um horário");
      this.jbService.novaAposta();
      this.jbService.data = this.jbService.dias[this.form.data_selecionada];
      this.jbService.horario = this.jbService.data.extracoes[this.form.horario];
    } catch (error) {
      this.notify.erroRest(error);
    }
  }

  getTipoJogos() {
    try {
      let pai = "0";
      try {
        pai = this.jbService.tp_selecionados[this.jbService.tp_selecionados.length - 1].id;
      } catch (error) { }
      return Object.keys(this.jbService.tipojogos_extracao[this.jbService.horario.id])
        .map((i) => this.jbService.tipojogos[i])
        .filter((p) => p.pai == pai);
    } catch (error) {
      return [];
    }
  }

  addTipo(i: any) {
    this.jbService.tp_selecionados.push(i);
  }

  getHorarios() {
    try {
      if (this.jbService.dias[this.form.data_selecionada].extracoes[0]) {
        return this.jbService.dias[this.form.data_selecionada].extracoes;
      } else {
        this.form.horario = "";
        return [];
      }
    } catch (error) {
      return [];
    }
  }

}
