import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
    
    getTextDateTimeUsToBR(data = new Date()){
        let hora = data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
        let min = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();
        let seg = data.getSeconds() < 10 ? "0" + data.getSeconds() : data.getSeconds();
        let dia = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
        let mes = (data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1);
        let ano = data.getFullYear();
        return `${dia}/${mes}/${ano} ${hora}:${min}:${seg}`;
    }

    diaSemana(data = new Date()){
        var semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
        return semana[data.getDay()]
    }
    moneyBr(text: any) {
        if (!text)
            return '';
        let money = String(Number(this.somenteNumero(text)));
        if (Number(money) > 9999999999999)
            money = "0";
        if (money.length < 3)
            money = this.zeroEsquerda(3, money);
        money = money.replace(/([0-9]{2})$/g, ",$1");
        if (money.length > 6)
            money = money.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        return money;
    }
    zeroEsquerda(zeros:any, text:any) {
        text = text + '';
        let resp = '';
        let size = zeros - text.length;
        for (let i = 0; i < size; i++) {
            resp += '0';
        }
        resp += text;
        return resp;
    }
    async sleep(ms:any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    somenteNumero(text:any) {
        if (!text)
            return '';
        let numeros:any = [];
        '0123456789'.split('')
            .map(value => numeros[value] = true);
        return String(text).split('')
            .filter(
                value => numeros[value]
            ).join('');
    }
    floatToMoney(text:any) {
        if (!text)
            return '0,00';
        let money = this.moneyBr(Number(text).toFixed(2).split('.').join(''));
        if (Number(text) < 0) return `-${money}`
        return money;
    }
    premioLabel(array: any) {
        let label:any = '';
        array.map(
            (p: any, i: any) => {
                let ant = Number(array[i - 1]);
                let prx = Number(array[i + 1]);
                p = Number(p);
                if (!ant) {
                    label += p;
                    return true;
                }
                if (p - 1 == ant && prx != p + 1) {
                    label += 'a' + p;
                    return true;
                }
                if (p - 1 == ant && p + 1 == prx) {
                    return true;
                }
                if (prx == p + 1 && ant != p - 1) {
                    label += ',' + p;
                    return true;
                }
                if (prx != p + 1 && ant != p - 1) {
                    label += ',' + p;
                    return true;
                }
                if (!prx && ant != p - 1) {
                    label += ',' + p;
                    return true;
                }else{
                    return ""
                }
            }
        );
        return label;
    }
}
