import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { ApiService } from 'src/app/service/api.service';
import { UtilService } from 'src/app/service/util.service';


@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.scss']
})
export class VendasComponent implements OnInit {

  constructor(public api: ApiService, public util: UtilService) { }

  loading = false
  public form = new FormGroup({
    inicio: new FormControl(dayjs().format("YYYY-MM-DD"), Validators.required),
    fim: new FormControl(dayjs().format("YYYY-MM-DD"), Validators.required)
  })
  public formFilterVenda = new FormGroup({
    textVenda: new FormControl("")
  })
  public formFilterPremio = new FormGroup({
    textPremio: new FormControl("")
  })
  filteredVendas:any = []
  filteredPremios:any = []
  exibeFechamento:any = false
  fechamento:any = null
  saldo:any = null

  async ngOnInit() {
    await this.getVendas()
    await this.getSaldo()
  }

  async getSaldo(){
    this.loading = true
    try {
      let resp:any = await this.api.getSaldoPonto()
      if (resp.saldo) this.saldo = resp.saldo
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  async getFechamento(){
    this.api.loading = true
    try {
      let {inicio, fim}:any = this.form.value
      let dados:any = await this.api.getFechamento(inicio, fim)
      if (dados.saldos) this.fechamento = dados.saldos
      this.exibeFechamento = true
    } catch (error) {
      console.log(error)
    }
    this.api.loading = false
  }

  getApostaModal(aposta: any) {
    this.api.cod_busca = aposta
    this.api.getAposta()
  }

  getTotal() {
    let total = 0
    for (let item of this.filteredVendas) total += item.valor_bruto
    return total
  }
  getTotalCom() {
    let total = 0
    for (let item of this.filteredVendas) total += item.comissao_ponto
    return total
  }
  getTotalPremio() {
    let total = 0
    for (let item of this.filteredPremios) total += item.premios
    return total
  }

  async getVendas() {
    this.api.loading = true
    let dados = this.form.value
    try {
      let { vendas, premios }: any = await this.api.getVendas(dados)
      this.api.vendas = vendas
      this.filteredVendas = vendas
      this.api.premios = premios
      this.filteredPremios = premios
    } catch (error) {
      console.log(error)
    }
    this.api.loading = false
  }

  async filterVendas() {
    try {
      await this.util.sleep(400)
      let text: any = this.formFilterVenda.value.textVenda
      this.filteredVendas = this.api.vendas.filter((o: any) => `${o.cod.toLowerCase()}-${(o.info_cliente?.nome.toLowerCase()) || " "}`.includes(text.toLowerCase()))
      this.filteredPremios= this.api.premios.filter((o: any) => `${o.cod.toLowerCase()}-${(o.info_cliente?.nome.toLowerCase()) || " "}`.includes(text.toLowerCase()))
    } catch (error) {
      console.log(error)
    }
  }
  async filterPremios() {
    try {
      await this.util.sleep(400)
      let text: any = this.formFilterPremio.value.textPremio
      this.filteredPremios= this.api.premios.filter((o: any) => `${o.cod.toLowerCase()}-${(o.info_cliente?.nome.toLowerCase()) || " "}`.includes(text.toLowerCase()))
    } catch (error) {
      console.log(error)
    }
  }

}
