<div *ngIf="!api.mostraMaisCotacoes" class="containerTable" (scroll)="onScroll($event)">
    <div class="col-md-12 pb-2 pt-3 d-flex paddingOnMobile bg-transparent" style="overflow: auto;">
        <button class="btn btn-sm btnCenter p-2 bg-transparent" *ngIf="maisDatas == true" style="display: flex"
            (click)="trocaValor(1)">
            <div *ngFor="let d of days; index as indice">
                <div class="maisDatas" (click)="escolheData(d)">
                    {{d | date: "dd/MM/yyyy"}}
                </div>
            </div>
        </button>
        <button class="btn btn-sm btnCenter p-2" (click)="trocaValor(1)">
            <div *ngIf="day && !maisDatas" class="data_selecionada">{{day | date: "dd/MM/yyyy"}}</div>
            <div *ngIf="!day || maisDatas" (click)="todasDatas()" class="data_selecionada">Todas as datas</div>
        </button>
        <!-- <div class="row">
            <div class="col">
                <button class="btn btn-primary" (click)="refresh()">Start</button>
            </div>
            <div class="col">
                <button class="btn btn-primary">+1 minuto</button>
            </div>
            <div class="col">
                <button class="btn btn-primary">gol casa</button>
            </div>
            <div class="col">
                <button class="btn btn-primary">gol fora</button>
            </div>
            <div class="col">
                <button class="btn btn-primary" (click)="api.sendMessage('HELL WORLD')">suspend</button>
            </div>
        </div> -->
    </div>
    <div class="col-md-12">
        <div>
            <div style="border-radius: 20px;" *ngFor="let campeonato of api.showCampeonatos()">
                <table class="tableLiga">
                    <thead>
                        <tr>
                            <th scope="col">{{campeonato.titulo}}</th>
                            <th scope="col">CASA</th>
                            <th scope="col">EMP.</th>
                            <th scope="col">FORA</th>
                            <th scope="col">+COT</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let j of campeonato.jogos">
                        <tr class="tr">
                            <td scope="row" class="row" id="item">
                                <div class="col-1 mr-1 bola" style="align-self: center;">
                                    <img src="../../../assets/soccer_ball_branco2.png" alt="" width="35" height="35">
                                </div>
                                <div class="col-1 mr-2 ibola" style="align-self: center;">
                                    <i class="fas fa-futbol"></i>
                                </div>
                                <div class="col">
                                    <div *ngIf="!j.ao_vivo">
                                        {{j.casa}} x {{j.fora}}
                                    </div>
                                    <div *ngIf="j.ao_vivo">
                                        {{j.casa}} <b class="score">{{j.gols_casa}}</b> x <b class="score">{{j.gols_fora}}</b> {{j.fora}}
                                    </div>
                                    <div *ngIf="!j.ao_vivo">
                                        {{j.data | date: 'MMMM d, y, HH:mm'}}
                                    </div>
                                    <div class="row" *ngIf="j.ao_vivo">
                                        <div class="pl-0 col" style="flex-direction: row; display: flex;" *ngIf="j.last_info">
                                            <span class="live_icon mr-2" *ngIf="j.last_info.playing">
                                                AO VIVO
                                            </span>
                                            <span *ngIf="!j.last_info.playing && j.last_info.minute == 45">
                                                INTERV.
                                            </span>
                                            <span *ngIf="!j.last_info.playing && j.last_info.minute == 90">
                                                FINALIZ.
                                            </span>
                                            <span *ngIf="j.last_info.playing">
                                                - {{j.last_info.minute}}'
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <ng-container  *ngIf="!api.loading_aovivo && api.showAoVivo">
                                <td scope="row" *ngFor="let c  of j.principais"
                                    (click)="api.escolheAposta({jogo: j, cotacao: c})" class="tdResult"
                                    [ngClass]="{'tdResultChecked': api.cotacaoSelect(c._id)}">
                                    <i class="fas fa-lock" *ngIf="c.suspended"></i>
                                    <b *ngIf="!c.suspended">{{c.valor.toFixed(2)}}</b>
                                </td>
                            </ng-container>
                            <ng-container  *ngIf="!api.showAoVivo">
                                <td scope="row" *ngFor="let c  of j.cotacoes.slice(0,3)"
                                    (click)="api.escolheAposta({jogo: j, cotacao: c})" class="tdResult"
                                    [ngClass]="{'tdResultChecked': api.cotacaoSelect(c._id)}">
                                    <i class="fas fa-lock" *ngIf="c.suspended"></i>
                                    <b *ngIf="!c.suspended">{{c.valor.toFixed(2)}}</b>
                                </td>
                            </ng-container>
                            <ng-container  *ngIf="api.loading_aovivo && api.showAoVivo">
                                <td scope="row" *ngFor="let i  of [0,1,2]" class="tdResult">
                                    <div class="spinner-grow text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </td>
                            </ng-container>
                            <td *ngIf="api.loading_aovivo && api.showAoVivo" scope="row" class="tdAllCotacoes">
                                <div class="spinner-grow text-light" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </td>
                            <td scope="row" [ngClass]=" template.getSite().titulo == 'ESPORTE SUL' ? 'tdAllCotacoes2' : 'tdAllCotacoes' " (click)="maisCotacoes(j)" *ngIf="!api.loading_aovivo && api.showAoVivo">
                                <i class="fas fa-lock" *ngIf="!j.cotacoes.length"></i>
                               <span  *ngIf="j.cotacoes.length"> + {{j.cotacoes.length - 3}}</span>
                            </td>
                            <td scope="row" [ngClass]=" template.getSite().titulo == 'ESPORTE SUL' ? 'tdAllCotacoes2' : 'tdAllCotacoes' " (click)="maisCotacoes(j)" *ngIf="!api.showAoVivo">
                                + {{j.cotacoes.length - 3}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="!loading && api.campeonatos.length >= 10" class="text-center mostrarMais" (click)="getMaisMobile()">
        MOSTRAR +
    </div>
</div>
<app-loading [loading]='loading'></app-loading>

<div class="col-md-12" *ngIf="api.mostraMaisCotacoes">
    <div class="row" style="align-items: center;">
        <div class="col">
            {{api.cotacoesJogoSelecionado.jogo.casa}} x {{api.cotacoesJogoSelecionado.jogo.fora}}
        </div>
        <div class="col">
            <div class="closeX" (click)="api.mostraMaisCotacoes = false">
                <i class="fas fa-long-arrow-alt-left" style="font-size: 30pt;"></i>
            </div>
        </div>
    </div>
    <div style=" overflow:scroll; height: 86vh;">
        <div class="tableMaisCotacoes" *ngFor="let principais of api.cotacoesJogoSelecionado.cotacoes">
            <div class="thSiglaPrincipal">{{principais.siglaMaior.toUpperCase()}}</div>
            <table class="tableLiga" style=" border: 0px solid !important;" *ngFor="let siglaMedia of principais.tipo">
                <thead>
                    <tr>
                        <th scope="col" colspan="2">{{siglaMedia.siglaMeio.toUpperCase()}}</th>
                    </tr>
                </thead>
                <tbody *ngFor="let cotacao of siglaMedia.jogos">
                    <tr class="tr">
                        <td scope="row" #item>{{cotacao.sigla[2].toUpperCase()}}</td>
                        <td scope="row"
                            (click)="api.escolheAposta({jogo: api.cotacoesJogoSelecionado.jogo, cotacao: cotacao}); api.mostraMaisCotacoes = false"
                            class="tdInAllCotacoes" [ngClass]="{'tdAllCotacoes': api.cotacaoSelect(cotacao._id)}">
                            <i class="fas fa-lock" *ngIf="cotacao.suspended"></i>
                            <span *ngIf="!cotacao.suspended">
                                {{cotacao.valor.toFixed(2)}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>