<div class="container" style="height: 100%; overflow: auto; padding-top: 100px;">
    <div class="row">
      <div class="col-md-4">
      </div>
      <div class="col-md-4 logo">
        <img src="../../../assets/LogoPalpitin.png" width="250" height="250" alt="">
      </div>
    </div>
    <h1>
      Política de Privacidade
    </h1>
    <p>
      A sua privacidade é importante para nós. É política do Palpitin respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site e/ou app Palpitin, e outros site e/ou app que possuímos e operamos.
    </p>
    <p>
      Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
    </p>
    <p>
      Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
    </p>
    <p>
      Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
    </p>
    <p>
      O nosso site e/ou app pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
    </p>
    <p>
      Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
    </p>
    <p>
      O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
    </p>
    <p>
      Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site e/ou app.
    </p>
    <p>
      Outras dúvidas podem ser esclarecidas ao localizar-nos em nosso endereço: Rua Curuça, Nº 226, Belém-PA Brasil.
    </p>
    <hr>
    <p>
      <em>
        Esta política é efetiva a partir de Janeiro/2021.
      </em>
    </p>
    <div style="min-height: 500px;"></div>
  </div>