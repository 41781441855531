<div class="jb">
    <div class="jb-page">
        <div class="areaJogo">
            <div class="caixa-jogo" *ngIf="jbService.step == 1 && !jbService.apostaRecebida">
                <h2 class="mb-5"><b>{{translator.translate('Data e Horário')}}</b></h2>
                <h4>{{translator.translate('Data')}}</h4>
                <select class="form-control-sm mb-3" style="width: 100%;" [(ngModel)]="form.data_selecionada">
                    <option *ngFor="let item of jbService.dias; let i = index" [value]="i"> {{item.dia}} -
                        {{item.data.split('-').reverse().join('/')}} </option>
                </select>
                <h4>{{translator.translate('Horário')}}</h4>
                <select class="form-control-sm" [(ngModel)]="form.horario" style="width: 100%;">
                    <option value=""> {{translator.translate('Selecione')}}... </option>
                    <option *ngFor="let item of getHorarios(); let i = index" [value]="i"> {{item.sigla}} </option>
                </select>
                <button class="btns btn-continua mt-4" (click)="continue()">
                    {{translator.translate('Continuar')}}
                </button>
            </div>

            <div class="caixa-jogo" *ngIf="jbService.step == 2 && !jbService.apostaRecebida">
                <h2 class="mb-5"><b>{{translator.translate('Tipo jogo')}}</b></h2>
                <div>
                    <h4>{{translator.translate('Selecionados')}}</h4>
                    <div style="height: 60px">
                        <button class="btns btn-escolha" *ngFor="let item of jbService.tp_selecionados">
                            {{item.sigla}}
                        </button>
                    </div>
                </div>
                <hr style="background-color: black;">
                <div class="d-flex justify-content-between flex-wrap">
                    <button class="btns btn-escolha" *ngFor="let item of getTipoJogos()" (click)="addTipo(item)">
                        {{item.sigla}}
                    </button>
                </div>
                <hr style="background-color: black;">
                <div style="width: 100%;" class="d-flex justify-content-around">
                    <button class="btns btn-controle mt-4" (click)="jbService.step = 1">
                        {{translator.translate('Voltar')}}
                    </button>
                    <button class="btns btn-controle mt-4" (click)="jbService.tp_selecionados = []">
                        {{translator.translate('Limpar')}}
                    </button>
                    <button class="btns btn-controle mt-4" (click)="jbService.preparaTemplate()">
                        {{translator.translate('Continuar')}}
                    </button>
                </div>
            </div>


            <div class="caixa-jogo" *ngIf="jbService.step == 3 && !jbService.apostaRecebida">
                <h2 class="mb-4"><b>{{jbService.getSiglas()}}</b></h2>
                <div *ngIf="jbService.ultimoTP()">
                    <div *ngIf="jbService.ultimoTP().tipo == 'I'" class="dados">
                        <!-- <lb-notificacao *ngIf="jbService.isAviso('addcampo')">
                            {{translator.translate('Click aqui para adicionar mais um campo!')}}
                        </lb-notificacao> -->
                        <div *ngFor="let item of jbService.campos; let i = index" class="d-flex justify-content-between align-content-center">
                            <label (click)="jbService.addCampoValido()">
                                {{i + 1}}º {{jbService.getSiglas()}}
                            </label>
                            <div class="d-flex align-items-center justify-content-end">
                                <input inputmode="numeric" class="valores form-control-sm" [(ngModel)]="item.value" [mask]="jbService.getMaskInput()" />
                                <div class="ml-2">
                                    <i class="fas fa-times-circle icon-close" (click)="jbService.removeCampo(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="jbService.ultimoTP().tipo == 'S'" class="dados">
                        <!-- <lb-notificacao *ngIf="jbService.isAviso('addcampo')">
                            {{translator.translate('Click aqui para adicionar mais um campo!')}}
                        </lb-notificacao> -->
                        <div class="d-flex justify-content-between align-content-center mb-3" *ngFor="let item of jbService.campos; let i = index">
                            <label (click)="jbService.addCampoValido()">
                                <div>
                                    {{i + 1}}º {{jbService.getSiglas()}}
                                </div>
                                <div style="font-size: 15pt; cursor: pointer;">
                                    <i class="fa fa-plus"></i>
                                </div>
                            </label>
                            <div class="d-flex align-items-center">
                                <button class="btns pr-3 pl-3" (click)="_mostraModal(i)">
                                    {{!item.value ? "Selecionar" : jbService.animais[item.value - 1].numero + " - " + jbService.animais[item.value - 1].animal}}
                                </button>
                                <div class="ml-2">
                                    <i class="fas fa-times-circle" (click)="jbService.removeCampo(i)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="containerBG" *ngIf="jbService.step == 3 && jbService.ultimoTP().tipo == 'S' && mostraModal">
                            <div (click)="mostraModal = false" class="closeX">
                                <i class="fa fa-times"></i>
                            </div>
                            <div class="containerModal p-2">
                                <!-- (click)="jbService.campos[i].value = bicho.numero; mostraModal = false" jbService.campos[i].value --> 
                                <div class="mb-4 pl-2 d-flex" *ngFor="let bicho of jbService.animais" (click)="jbService.campos[campoSelecionado].value = bicho.numero; mostraModal = false; campoSelecionado = null"  style="background-color: black; border-radius: 5px; cursor: pointer;">
                                    <i style="font-size: 60px; color: white" [ngClass]="bicho.icone"></i>
                                    <div class="nomeBicho">
                                        <h4 style="vertical-align: middle;">{{bicho.numero}} - {{bicho.animal}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="jbService.ultimoTP().tipo == 'C'">
                        <label>
                            {{translator.translate('Selecionados')}}
                        </label>
                        <div class="d-flex justify-content-start flex-wrap" style="height: auto !important">
                            <button *ngFor="let item of jbService.getCamposAtivos()"
                                (click)="jbService.clickFormCheck(item)" class="btns m-1">
                                {{item.value}}
                            </button>
                        </div>
                        <hr>
                        <div class="teclas">
                            <button *ngFor="let item of jbService.campos" class="btns m-1"
                                [ngClass]="{'btn-marcado': item.status}" (click)="jbService.clickFormCheck(item)">
                                {{item.value}}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Premios por selecionar -->
                <div *ngIf="jbService.ultimoTP() && !jbService.ultimoTP().premio_fixo.length" style="margin-top: 20px;">
                    <div class="premios">
                        <div class="d-flex justify-content-around mb-3" *ngIf="template.getSite().title != 'Red Lottery' ">
                            <button *ngFor="let item of jbService.ultimoTP().botoes_premio"
                                (click)="jbService.marcaPremio(item)" class="btns">
                                {{item}}
                            </button>
                            <button (click)="jbService.marcaPremio('')" class="btns">
                                Desmar.
                            </button>
                            <!-- <button class="btns" (click)="teste()">
                                teste
                            </button> -->
                        </div>
                        <div *ngFor="let item of jbService.premios; let i = index" class="premio">
                            <label class="d-flex justify-content-between align-content-center">
                                {{item.premio}}º {{translator.translate('Prêmio')}}
                                <input [(ngModel)]="item.value" type="checkbox" />
                            </label>
                            <hr style="margin: 5px 0px;">
                        </div>
                    </div>
                </div>

                <!-- Pagamento - Finalizar -->
                <div *ngIf="jbService.ultimoTP().inicia_jogos.length">
                    <div *ngFor="let item of jbService.valores" class="d-flex justify-content-between mt-2">
                        <label>
                            {{item.tp.sigla}}
                        </label>
                        <input inputmode="numeric" class="valores form-control-sm" [(ngModel)]="item.value"
                            [options]="{ align: 'right' }" currencyMask />
                    </div>
                </div>
                <div *ngIf="!jbService.ultimoTP().inicia_jogos.length">
                    <div class="d-flex justify-content-between">
                        <label>
                            {{translator.translate('VALOR')}}
                        </label>
                        <div style="width: 50%">
                            <input inputmode="numeric" class="valores form-control-sm" [(ngModel)]="jbService.valor_jogo"
                            [options]="{ align: 'right' }" currencyMask />
                            <br>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;" class="d-flex justify-content-around">
                    <button class="btns btn-controle mt-4" style="width: 40% !important;" (click)="jbService.step = 2">
                        {{translator.translate('Voltar')}}
                    </button>
                    <button class="btns btn-controle mt-4" [disabled]="!jbService.validaJogo()" style="width: 40% !important;"
                        (click)="jbService.salvaJogo()">
                        {{translator.translate('Salvar')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end finaliza">
        <app-finaliza-jb style="width: 100%; height: 100vh;"></app-finaliza-jb>
    </div>
</div>
<!--  -->
