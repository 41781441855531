<div class="finalizaBG" *ngIf="!showCadastrar && showLogin">
    <form class="container-login" [formGroup]="login">
        <div class="closeX" (click)="show(1)">
            <i class="fas fa-times" style="font-size: 30pt;"></i>
        </div>
        <!-- <div class="text-center" style="font-size: 100pt;">
            <i class="fas fa-user-secret"></i>
        </div> -->
        <div class="col-md-12">
            <div class="label">Nome usuário: </div>
            <input type="text" class="col-sm-12 input" formControlName="username" autocomplete="username">
        </div>
        <div class="col-md-12 mt-3">
            <div class="label">Senha: </div>
            <input type="password" formControlName="senha" class="col-sm-12 input">
        </div>
        <div class="col-md-12 mt-4">
            <button type="submit" class="btn btn-dark w-100" style="background-color: rgba(0, 0, 0, 0.568) !important; border-radius: 30px;" (click)='clickLogin()'>
                <h5>Entrar</h5>
            </button>
        </div>
        <div class="col-md-12 mt-2 mb-2 text-center" (click)="show(0)" style="font-size: 10pt; cursor: pointer;">
            Criar Conta
        </div>
    </form>
</div>

<div class="finalizaBG" *ngIf="showCadastrar && showLogin">
    <div class="container-login" [formGroup]="cadastrar">
        <div class="closeX" (click)="show(1)">
            <i class="fas fa-times" style="font-size: 30pt;"></i>
        </div>
        <!-- <div class="text-center" style="font-size: 100pt;">
            <i class="fas fa-user-secret"></i>
        </div> -->
        <div class="col-md-12">
            <div class="label">Nome completo: </div>
            <input type="text" class="col-sm-12 input"  formControlName="nome">
        </div>
        <div class="col-md-12 mt-3">
            <div class="label">Nome de usuário: </div>
            <input type="text" class="col-sm-12 input" autocomplete="username" formControlName="username">
        </div>
        <div class="col-md-12 mt-3">
            <div class="label">Senha: </div>
            <input type="password" class="col-sm-12 input" formControlName="senha">
        </div>
        <div class="col-md-12 mt-3">
            <div class="label">Confirmar senha: </div>
            <input type="password" class="col-sm-12 input" formControlName="senha2">
        </div>
        <div class="row" *ngFor="let aviso of avisos">
            <div class="col text-center">
                <b class="text-white">{{aviso.texto}}</b>
            </div>
        </div>
        <div class="col-md-12 mt-4">
            <button (click)="clickNovaConta()" class="btn btn-dark w-100" style="background-color: rgba(0, 0, 0, 0.568) !important; border-radius: 30px;">
                <h5>Cadastrar</h5>
            </button>
        </div>
        <div class="col-md-12 mt-2 mb-2 text-center" (click)="show(0)" style="font-size: 10pt; cursor: pointer;">
            Já tenho conta
        </div>
    </div>
</div>