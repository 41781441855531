<div style="height: 100%; overflow: scroll">
    <div class="row p-0" [formGroup]="form">
        <div class="col-md p-0 pb-2 d-flex paddingOnMobile">
            <input formControlName="inicio" type="date" class="form-control" placeholder="Data Inicial">
        </div>
        <div class="col-md-2 text-center" style="align-self: center;">
            <b>á</b>
        </div>
        <div class="col-md p-0 pb-2 d-flex paddingOnMobile">
            <input formControlName="fim" type="date" class="form-control" placeholder="Data Final">
        </div>
    </div>
    <div class="row col-md-12 p-0" [formGroup]="formFilterVenda">
        <div class="col-md-6 p-0 pb-2 d-flex paddingOnMobile">
            <input type="text" class="form-control" formControlName="textVenda" placeholder="Buscar"
                (keyup)="filterVendas()">
        </div>
        <div class="col-md-6 p-0 pb-2 pl-3 paddingOnMobile" *ngIf="exibeFechamento">
            <button class="btn btn-outline-light btn-sm w-100 h-100"
                style="letter-spacing: 2px;font-size: 14pt; font-weight: bold;"
                (click)="exibeFechamento = false; fechamento = null">
                Voltar
            </button>
        </div>
        <div class="col-md-3 p-0 pb-2 pl-3 paddingOnMobile" *ngIf="!exibeFechamento">
            <button class="btn btn-outline-light btn-sm w-100 h-100"
                style="letter-spacing: 2px;font-size: 14pt; font-weight: bold;" (click)="getVendas()">
                Buscar
            </button>
        </div>
        <div class="col-md-3 p-0 pb-2 pl-3 paddingOnMobile" *ngIf="!exibeFechamento">
            <button class="btn btn-outline-light btn-sm w-100 h-100"
                style="letter-spacing: 2px;font-size: 14pt; font-weight: bold;" (click)="getFechamento()">
                Fech. Resumido
            </button>
        </div>
    </div>

    <hr style="background-color: white;">

    <div class="col-md-12" *ngIf="!exibeFechamento">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">SALDO</th>
                        <th scope="col">
                            <b *ngIf="saldo">{{saldo | currency: "BRL"}}</b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
    <hr style="background-color: white;" *ngIf="!exibeFechamento">
    <div class="col-md-12" *ngIf="!exibeFechamento">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">APOSTAS</th>
                        <th scope="col">VENDA</th>
                        <th scope="col">COMISSÃO</th>
                        <th scope="col">PREMIOS</th>
                        <th scope="col">TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            {{filteredVendas.length}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{getTotal() | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{getTotalCom() | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{getTotalPremio() | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{(getTotal() - getTotalCom() - getTotalPremio()) | currency: "BRL"}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-md-12" *ngIf="exibeFechamento">
        <div style="border-radius: 20px;" *ngIf="fechamento">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">DESC.</th>
                        <th scope="col">VALOR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            PERÍODO
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.de}} à {{fechamento.ate}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            VENDA
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.vendas}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            COMISSÃO VENDA
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.comissao}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            PRÊMIOS
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.premios}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdFooter">
                            SALDO
                        </td>
                        <td scope="row" class="tdFooter">
                            {{fechamento.liquido}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            COM. SALDO
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.com_saldo}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdFooter">
                            TOTAL
                        </td>
                        <td scope="row" class="tdFooter">
                            {{fechamento.saldo}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdResult">
                            ACERTO
                        </td>
                        <td scope="row" class="tdResult">
                            {{fechamento.acerto}}
                        </td>
                    </tr>
                    <tr class="tr hovered">
                        <td scope="row" class="tdFooter">
                            LÍQUIDO
                        </td>
                        <td scope="row" class="tdFooter">
                            {{fechamento.total}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <hr style="background-color: white;" *ngIf="!exibeFechamento">


    <div class="col-md-12" *ngIf="!exibeFechamento">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">DATA</th>
                        <th scope="col">FEITA</th>
                        <th scope="col">COD</th>
                        <th scope="col">CLIENTE</th>
                        <th scope="col">VALOR</th>
                        <th scope="col">COM.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr hovered" *ngFor="let item of filteredVendas" (click)="getApostaModal(item.cod)">
                        <td scope="row" class="tdResult">
                            {{item.dateBr}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.createdAt | date: "dd/MM/yyyy HH:mm"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.cod}}
                        </td>
                        <td scope="row" class="tdResult">
                            <span *ngIf="item.info_cliente?.nome">{{item.info_cliente.nome}}</span>
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.valor_bruto | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.comissao_ponto | currency: "BRL"}}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td scope="row" class="tdFooter">

                        </td>
                        <td scope="row" class="tdFooter">

                        </td>
                        <td scope="row" class="tdFooter">
                            VENDA TOTAL
                        </td>
                        <td scope="row" class="tdFooter">
                            {{filteredVendas.length}}
                        </td>
                        <td scope="row" class="tdFooter">
                            {{ getTotal() | currency: "BRL"}}
                        </td>
                        <td scope="row" class="tdFooter">
                            {{getTotalCom() | currency: "BRL"}}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <hr style="background-color: white;" *ngIf="!exibeFechamento">
    <!-- <div class="row col-md-12 p-0" [formGroup]="formFilterPremio">
        <div class="col-md-8 p-0 pb-2 d-flex paddingOnMobile">
            <input type="text" class="form-control" formControlName="textPremio" placeholder="Buscar" (keyup)="filterPremios()">
        </div>
    </div> -->
    <div class="col-md-12" *ngIf="!exibeFechamento">
        <div style="border-radius: 20px;">
            <table class="tableLiga">
                <thead>
                    <tr>
                        <th scope="col">DATA</th>
                        <th scope="col">FEITA</th>
                        <th scope="col">COD</th>
                        <th scope="col">CLIENTE</th>
                        <th scope="col">PRÊMIO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr hovered" *ngFor="let item of filteredPremios" (click)="getApostaModal(item.cod)">
                        <td scope="row" class="tdResult">
                            {{item.dateBr}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.createdAt | date: "dd/MM/yyyy HH:mm"}}
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.cod}}
                        </td>
                        <td scope="row" class="tdResult">
                            <span *ngIf="item.info_cliente?.nome">{{item.info_cliente.nome}}</span>
                        </td>
                        <td scope="row" class="tdResult">
                            {{item.premios | currency: "BRL"}}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td scope="row" class="tdFooter">

                        </td>
                        <td scope="row" class="tdFooter">

                        </td>
                        <td scope="row" class="tdFooter">
                            PRÊMIO TOTAL
                        </td>
                        <td scope="row" class="tdFooter">
                            {{filteredPremios.length}}
                        </td>
                        <td scope="row" class="tdFooter">
                            {{ getTotalPremio() | currency: "BRL"}}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <hr style="background-color: white;">
</div>
<view-aposta></view-aposta>