import { PoliticasPrivacidadeComponent } from './components/politicas-privacidade/politicas-privacidade.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from '../app/components/home/inicio/inicio.component'
import { CreditosComponent } from '../app/components/home/creditos/creditos.component'
import { ResultadosComponent } from './components/home/resultados/resultados.component';
import { JbComponent } from './components/home/jb/jb.component';
import { VendasComponent } from './components/home/vendas/vendas.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },{
    path: 'inicio',
    component: InicioComponent,
  },
  {
    path: 'meus-creditos',
    component: CreditosComponent,
  },
  {
    path: 'vendas',
    component: VendasComponent,
  },
  {
    path: 'resultados',
    component: ResultadosComponent,
  },
  {
    path: 'jb',
    component: JbComponent,
  },
  {
    path: 'politicas/privacidade',
    component: PoliticasPrivacidadeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
