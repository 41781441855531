import { BrowserModule } from "@angular/platform-browser";
import { isDevMode, LOCALE_ID, NgModule } from "@angular/core";
import localeBr from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeBr, "pt");
import { NgxMaskModule, IConfig } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MenuComponent } from "././components/template/menu/menu.component";
import { InicioComponent } from "./components/home/inicio/inicio.component";
import { FooterTemplateComponent } from "./components/template/footer-template/footer-template.component";
import { LeftWebMenuComponent } from "./components/template/left-web-menu/left-web-menu.component";
import { FinalizaJogoComponent } from "./components/template/finaliza-jogo/finaliza-jogo.component";
import { FinalizaMobileComponent } from "./components/template/finaliza-mobile/finaliza-mobile.component";
import { LoadingComponent } from "./components/template/loading/loading.component";
import { LoginComponent } from "./components/home/login/login.component";
import { CreditosComponent } from "./components/home/creditos/creditos.component";
import { ResultadosComponent } from "./components/home/resultados/resultados.component";
import { ViewApostaComponent } from "./components/template/view-aposta/view-aposta.component";
import { JbComponent } from "./components/home/jb/jb.component";
import { FinalizaJbComponent } from "./components/template/finaliza-jb/finaliza-jb.component";
import { PoliticasPrivacidadeComponent } from './components/politicas-privacidade/politicas-privacidade.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { VendasComponent } from './components/home/vendas/vendas.component';


const config: SocketIoConfig = {
   url: isDevMode() ? 'https://fut-api-dot-palpitin2api.rj.r.appspot.com' : 'https://fut-api-dot-palpitin2api.rj.r.appspot.com', 
   options: {} 
  };

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    InicioComponent,
    FooterTemplateComponent,
    LeftWebMenuComponent,
    FinalizaJogoComponent,
    FinalizaMobileComponent,
    LoadingComponent,
    LoginComponent,
    CreditosComponent,
    ResultadosComponent,
    ViewApostaComponent,
    JbComponent,
    FinalizaJbComponent,
    PoliticasPrivacidadeComponent,
    VendasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot({}),
    NgxCurrencyModule.forRoot({
      align: "right",
      allowNegative: false,
      allowZero: true,
      decimal: ",",
      precision: 2,
      prefix: "",
      suffix: "",
      thousands: ".",
      nullable: true,
    }),
    SocketIoModule.forRoot(config)
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-br' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  
}
