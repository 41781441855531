<div class="finaliza-jb">
    <h1 class="text-center mb-2">{{template.getSite().titulo}}</h1>
    <h1 class="text-left">{{translator.translate('APOSTA')}}: {{jbService.apostaRecebida ? jbService.apostaRecebida.codvalidacao
        : "---------"}}</h1>
    <h1 class="text-left" *ngIf="jbService.apostaRecebida">{{translator.translate('VALIDAÇÃO')}}:
        {{jbService.apostaRecebida.codvalidacao}}</h1>
    <h1 class="text-left">{{translator.translate('DATA')}}: {{jbService.getDataAposta()}}</h1>
    <p *ngIf="!template.getSite().estadium && !jbService.apostaRecebida">{{translator.translate('Feita em')}}: {{dataAposta | date: "dd/MM/yyyy - HH:mm:ss"}} </p>
    <p *ngIf="!template.getSite().estadium && jbService.apostaRecebida">{{translator.translate('Feita em')}}: {{jbService.apostaRecebida.dataView}} </p>
    <hr class="mt-1 mb-1">
    <h1 class="text-center">{{translator.translate('JOGOS')}}
        <button *ngIf="jbService.jogos_feitos.length && !jbService.apostaRecebida" type="button"
            class="text-center float-right btn-remove" (click)="jbService.reset()">
            <i class="fas fa-trash-alt"></i>
        </button>
    </h1>
    <hr class="mt-1 mb-1">
    <div class="table">
        <table>
            <tr>
                <td>
                    {{translator.translate('TIPO')}}
                </td>
                <td>
                    {{translator.translate('JOGO')}}
                </td>
                <td>
                    {{translator.translate('PREMIO')}}
                </td>
                <td class="text-right">
                    {{translator.translate('VALOR')}}
                </td>
            </tr>
            <tr *ngFor="let item of jbService.jogos_feitos">
                <td>
                    {{jbService.getSiglasJogoFeiro(item)}}
                </td>
                <td>
                    {{item.campos.join(' ')}}
                </td>
                <td>
                    {{jbService.premioLabel2(item.premios)}}
                </td>
                <td class="text-right">
                    {{ item.valor | currency: ' ' }}
                </td>
            </tr>
        </table>
    </div>
    <h1 class="text-left input-valor">{{translator.translate('APOSTADO')}}: {{ jbService.totalAposta() | currency: ' '}}</h1>
    <p class="text-center" *ngIf="jbService.totalAposta() < 1">Valor mínimo de 1,00</p>

    <div *ngIf="jbService.transmitindo" class="text-center">
        <h3>{{translator.translate('Finalizando...')}}</h3>
    </div>

    <div *ngIf="jbService.apostaRecebida">
        <div class="row text-center">
            <div class="col text-center">
                <h2>{{translator.translate('Aposta Finalizada com sucesso!')}}</h2>
                <h3 style="color: var(--corOuro)" *ngIf='jbService.apostaRecebida.status == 1'>{{jbService.apostaRecebida.cod}}</h3>
                <p *ngIf='jbService.apostaRecebida.status != 1'>{{translator.translate('Agora é só enviar o código abaixo para um cambista! Boa Sorte!')}}</p>
                <p *ngIf='jbService.apostaRecebida.status == 1'>A sua aposta foi paga com seu crédito e já é válida! Boa Sorte!</p>
            </div>
        </div>
        <div class="col-md-12 text-center mt-4 mb-4" *ngIf='jbService.apostaRecebida.status != 1'>
            <h5>Cod. de {{translator.translate('validação')}}</h5>
            <div class="row">
                <div class="col">
                    <h2 style="color: var(--corOuro)">{{jbService.apostaRecebida.codvalidacao}}</h2>
                </div>
            </div>
            <img class="mt-4" [src]="jbService.apostaRecebida.qr">
        </div>
        <div class="row">
            <div class="col text-center">
                <button class="btn btn-dark w-100 mb-1" (click)="show_form_credito = false; jbService.apostaRecebida = null">
                    <h5>{{translator.translate('Nova Aposta')}}</h5>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!jbService.finalizado && !jbService.transmitindo && jbService.totalAposta() >= 1 && jbService.jogos_feitos.length">
        <!-- <div> -->
        <!-- [ngClass]="{'disabled': jb.jogos_feitos.length < 1 || jb.totalAposta() < 1 }" > -->
        <h2 class="text-center mt-2">
            {{translator.translate('Finalizar')}}
        </h2>
        <button class="btns btn-continua mt-2" (click)="jbService.finalizaOperador(1)" *ngIf="!show_form_credito">
            {{translator.translate('Pagar com cambista')}}
        </button>
        <button class="btns btn-continua mt-2" [disabled]="jbService.jogos_feitos.length < 1 || jbService.totalAposta() < 1" (click)="liberaFormCredito()" *ngIf="api.getSessao() && !show_form_credito">
            Pagar com crédito
        </button>
        <div *ngIf="show_form_credito">
            <select class="form-control-sm w-100" [(ngModel)]="jbService.credito_selecionado">
                <option [value]="null">Selecione o crédito</option>
                <option [value]="item._id" *ngFor="let item of api.creditos">{{item.token}} - Saldo: {{item.saldo | currency: "BRL"}}</option>
            </select>
            <div class="w-100 text-center" *ngIf="show_form_credito && !jbService.credito_selecionado">
                <p>Selecione seu crédito</p>
            </div>
            <button class="btns btn-continua" [disabled]="!jbService.credito_selecionado" (click)="jbService.finalizaOperador(3)">
                Pagar com crédito
            </button>
            <button class="btns btn-continua mb-2" (click)="jbService.credito_selecionado = null; show_form_credito = false">
                Voltar
            </button>
        </div>
        <!-- <button (click)="jbService.pagarComCreditoMeusCreditos(3)">
                <i class="fas fa-address-card"></i> {{translator.translate('Meus Créditos')}}
            </button> -->
        <!-- <div *ngIf="jbService.jogos_feitos.length < 1 || jbService.totalAposta() < 1" class="bloq"></div> -->
    </div>
</div>