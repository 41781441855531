import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { UtilService } from "./util.service";
import { MenuService } from "./menu.service";
import * as QRCode from "qrcode";
import { TemplateService } from "./template.service";


@Injectable({
  providedIn: "root",
})
export class JbService {
  constructor(
    private server: ApiService,
    private router: Router,
    private util: UtilService,
    public menu: MenuService,
    public api: ApiService,
    public template: TemplateService
  ) {}

  public loading: any = false;
  public jb: any = false;
  public busca: any = {
    loading: false,
    cod: null,
    status: false,
    dados: null,
    erro: false,
  };
  public camera_busca: any = false;
  public opcoes_finalizar: any = false;
  public mapa_bolo: any = { loading: false, dados: null, status: false };
  public ranking_bolo: any = { loading: false, dados: null, status: false };
  public bolo: any = {
    cod: 0,
    comissao: 0,
    id: 0,
    inicio: "",
    termino: "",
    valor: 0,
  };
  public disable_input: any = false;
  public opcoes_credito: any = false;
  public scan_qr: any = false;
  public erro: any = { show: false, msg: "" };
  public data_sorteio: any = null;
  public data_sorteio_br: any = null;
  public sorteios: any = [];
  public posicao: any = null;
  public data: any = null;
  public horario: any = null;
  public dias: any = null;
  public extracao: any = null;
  public jogos_feitos: any = [];
  public premios: any = [];
  public alerts: any = [];
  public tipojogos: any = null;
  public jogo_pai: any = 0;
  public tipojogos_extracao: any = null;
  public credito_selecionado: any = null;
  public step: any = 1;
  public show_pule: any = false;
  public show_corpo: any = true;
  public valor_jogo: any = 0;
  public tp_selecionados: any = [];
  public botoes_check: any = [];
  public btns_premio_fixo: any = [];
  public campos: any = [];
  public valores: any = [];
  public resumo_pule: any = {
    show: false,
    tipo_aposta: 0,
    dados: { cod: [], criado: "" },
  };
  public show_resultado: any = false;
  public libera_pagamento: any = false;
  public data_atual: any = null;
  public loading_resultado: any = false;
  public extracao_ativa: any = null;
  public bichos: any = {
    1: "AVESTRUZ",
    2: "ÁGUIA",
    3: "BURRO",
    4: "BORBOLETA",
    5: "CACHORRO",
    6: "CABRA",
    7: "CARNEIRO",
    8: "CAMELO",
    9: "COBRA",
    10: "COELHO",
    11: "CAVALO",
    12: "ELEFANTE",
    13: "GALO",
    14: "GATO",
    15: "JACARÉ",
    16: "LEÃO",
    17: "MACACO",
    18: "PORCO",
    19: "PAVÃO",
    20: "PERU",
    21: "TOURO",
    22: "TIGRE",
    23: "URSO",
    24: "VEADO",
    25: "VACA",
  };
  // public _bichos: any = [
  //   "AVESTRUZ",
  //   "ÁGUIA",
  //   "BURRO",
  //   "BORBOLETA",
  //   "CACHORRO",
  //   "CABRA",
  //   "CARNEIRO",
  //   "CAMELO",
  //   "COBRA",
  //   "COELHO",
  //   "CAVALO",
  //   "ELEFANTE",
  //   "GALO",
  //   "GATO",
  //   "JACARÉ",
  //   "LEÃO",
  //   "MACACO",
  //   "PORCO",
  //   "PAVÃO",
  //   "PERU",
  //   "TOURO",
  //   "TIGRE",
  //   "URSO",
  //   "VEADO",
  //   "VACA",
  // ];
  // public imgs_bichos: any = [
  //   "../assets/animais/carneiro.jpg",
  //   "../assets/animais/camelo.jpg",
  //   "../assets/animais/cobra.jpg",
  //   "../assets/animais/coelho.jpg",
  //   "../assets/animais/cavalo.jpg",
  //   "../assets/animais/elefante.jpg",
  //   "../assets/animais/galo.jpg",
  //   "../assets/animais/gato.jpg",
  //   "../assets/animais/jacare.jpg",
  //   "../assets/animais/leao.jpg",
  //   "../assets/animais/macaco.jpg",
  //   "../assets/animais/porco.jpg",
  //   "../assets/animais/pavao.jpg",
  //   "../assets/animais/peru.jpg",
  //   "../assets/animais/touro.jpg",
  //   "../assets/animais/tigre.jpg",
  //   "../assets/animais/urso.jpg",
  //   "../assets/animais/veado.jpg",
  //   "../assets/animais/vaca.jpg",
  // ]
  public animais = [
    {
      animal: "AVESTRUZ",
      icone: "icon-avestruz",
      numero: 1
    },
    {
      animal: "ÁGUIA",
      icone: "icon-aguia",
      numero: 2
    },
    {
      animal: "BURRO",
      icone: "icon-burro",
      numero: 3
    },
    {
      animal: "BORBOLETA",
      icone: "icon-borboleta",
      numero: 4
    },
    {
      animal: "CACHORRO",
      icone: "icon-cachorro",
      numero: 5
    },
    {
      animal: "CABRA",
      icone: "icon-cabra",
      numero: 6
    },
    {
      animal: "CARNEIRO",
      icone: "icon-carneiro",
      numero: 7
    },
    {
      animal: "CAMELO",
      icone: "icon-camelo",
      numero: 8
    },
    {
      animal: "COBRA",
      icone: "icon-cobra",
      numero: 9
    },
    {
      animal: "COELHO",
      icone: "icon-coelho",
      numero: 10
    },
    {
      animal: "CAVALO",
      icone: "icon-cavalo",
      numero: 11
    },
    {
      animal: "ELEFANTE",
      icone: "icon-elefante",
      numero: 12
    },
    {
      animal: "GALO",
      icone: "icon-galo",
      numero: 13
    },
    {
      animal: "GATO",
      icone: "icon-gato",
      numero: 14
    },
    {
      animal: "JACARÉ",
      icone: "icon-jacare",
      numero: 15
    },
    {
      animal: "LEÃO",
      icone: "icon-leao",
      numero: 16
    },
    {
      animal: "MACACO",
      icone: "icon-macaco",
      numero: 17
    },
    {
      animal: "PORCO",
      icone: "icon-porco",
      numero: 18
    },
    {
      animal: "PAVÃO",
      icone: "icon-pavao",
      numero: 19
    },
    {
      animal: "PERU",
      icone: "icon-peru",
      numero: 20
    },
    {
      animal: "TOURO",
      icone: "icon-touro",
      numero: 21
    },
    {
      animal: "TIGRE",
      icone: "icon-tigre",
      numero: 22
    },
    {
      animal: "URSO",
      icone: "icon-urso",
      numero: 23
    },
    {
      animal: "VEADO",
      icone: "icon-veado",
      numero: 24
    },
    {
      animal: "VACA",
      icone: "icon-vaca",
      numero: 25
    },
  ]

  public transmitindo: any = false;
  public finalizado: any = false;
  public showMeusCreditos: any = false;
  public inputCreditoValue: any = "";
  public showInputCredito: any = false;
  public meuCreditoSelecionado: any = null;
  public apostaRecebida: any = null;
  public showLogin: any = false;
  public creditos: any = [];

  async pagarComCreditoMeusCreditos(type = 3) {
    if (this.transmitindo) return false;
    if (!this.getSessao()) {
      this.showLogin = true;
      return false;
    } else {
      await this.meusCreditos();
      this.showMeusCreditos = true;
      if (this.meuCreditoSelecionado) {
        this.finalizaOperador(type);
      } else return "";

      return setTimeout(() => {
        this.showMeusCreditos = false;
      }, 10000);
    }
  }

  async meusCreditos() {
    this.loading = true;
    try {
      let data = await this.server.get(`/site/cliente/creditos`);
      this.creditos = data;
    } catch (error) {
      console.log(error);
      throw error
    }
    this.loading = false;
  }

  pagarComCredito(type = 2): any {
    if (this.transmitindo) return false;
    this.showInputCredito = !this.showInputCredito;
    if (this.inputCreditoValue.length == 8) {
      this.finalizaOperador(type);
    }
  }

  generateQRCode(data: any) {
    let opts: any = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 0.8,
      margin: 2,
    };
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(data, opts, (err: any, url: any) => {
        if (err) reject(err);
        resolve(url);
      });
    });
  }


  async finalizaOperador(type: any): Promise<any> {
    if (this.transmitindo) return false;
    this.transmitindo = true;
    this.api.loading = true
    try {
      let data = this.data.data;
      let horario = this.horario.extracao;
      let valor = this.totalAposta();
      let credito = this.inputCreditoValue;
      let idcredito = null;
      let link = "/site/jb/aposta";
      let jogos = this.jogos_feitos;
      for (let i in jogos) {
        jogos[i].ids = [];
        jogos[i].dados = [];
        jogos[i].siglas = [];
        for (let tp of jogos[i].tps) {
          jogos[i].ids.push(tp.id);
          jogos[i].siglas.push(tp.sigla);
        }
        for (let campo of jogos[i].campos) {
          jogos[i].dados.push(campo);
        }
      }
      if (type == 3) {
        if (this.credito_selecionado) {
          let _credito = this.api.creditos.find((c: any) => c._id == this.credito_selecionado);
          if (_credito) idcredito = _credito._id
        }
        link = "/site/jb/cliente/aposta";
      }
      if (this.getSessao()) link = "/site/jb/cliente/aposta";
      if (credito.length != 8) credito = "";
      let resp = await this.server.post(link, {
        jogos,
        valor,
        credito,
        idcredito,
        data,
        horario,
      });
      // let data = await this.server.post(link, { jogos, valor, credito, idcredito, });
      this.apostaRecebida = resp.apostas[0];
      this.apostaRecebida.codvalidacao = resp.apostas[0].cod_validacao
      this.apostaRecebida.qr =  await this.generateQRCode(resp.apostas[0].cod_validacao);
      if (this.apostaRecebida.status == 1) this.apostaRecebida.codvalidacao = this.apostaRecebida.cod
      this.finalizado = true;
      this.inputCreditoValue = "";
      this.step = 1;
    } catch (error) {
      this.inputCreditoValue = "";
      console.log(error);
    }
    this.meuCreditoSelecionado = null;
    this.transmitindo = false;
    this.api.loading = false
  }

  novaAposta() {
    this.finalizado = false;
    this.menu.showAposta = false;
    this.jogos_feitos = [];
    this.step = 2;
    this.apostaRecebida = null;
  }

  getSessao() {
    return localStorage.getItem("token");
  }

  getSiglas() {
    try {
      return this.tp_selecionados.map((v: any) => v.sigla).join(" ");
    } catch (error) {
      return "";
    }
  }

  async init() {
    this.loading = true;
    try {
      let dados = await this.server.get("/site/jb/init");
      if (dados.dias) {
        for (let index_dia in dados.dias) {
          if (dados.dias[index_dia].extracoes) {
            let arr = [];
            for (let index_extracao in dados.dias[index_dia].extracoes) {
              if (dados.dias[index_dia].extracoes[index_extracao].id)
                arr.push(dados.dias[index_dia].extracoes[index_extracao]);
            }
            dados.dias[index_dia].extracoes = arr;
          }
        }
      }
      this.dias = dados.dias;
      this.data = dados.dias[0];
      this.tipojogos = dados.tipojogos;
      this.tipojogos_extracao = dados.tipo_por_extracao;
      this.data_sorteio_br = dados.data_atual_br;
      this.data_sorteio = dados.data_atual;
      this.data_atual = dados.data_atual;
      if (dados.bolo.length) this.bolo = dados.bolo[0];
      await this.get_sorteio(this.data_sorteio);
    } catch (error) {}
    this.loading = false;
    return true;
  }

  limpaPremio() {
    for (let p of this.premios) {
      p.value = false;
    }
  }
  marcaPremio(btn: any) {
    try {
      this.limpaPremio();
      if (btn.indexOf("-") != -1) {
        let btns = btn.split("-").map((v: any) => Number(v));
        for (let x = btns[0]; x <= btns[1]; x++) {
          for (let p of this.premios) {
            if (p.premio == x) p.value = true;
          }
        }
      }
      if (btn.indexOf(";") != -1) {
        let btns = btn.split(";");
        for (let x of btns) {
          for (let p of this.premios) {
            if (p.premio == x) p.value = true;
          }
        }
      }
    } catch (error) {}
  }

  clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  addCampo() {
    this.campos.push({ value: "" });
  }

  openListAnimal(item: any) {
    let botoes: any = [];
    Object.keys(this.bichos).map((b) => {
      botoes.push({
        titulo: `${b} - ${this.bichos[b]}`,
        img: `../../assets/img/jb/bichos/${b}.png`,
        click: () => {
          item.value = b;
        },
      });
    });

    this.alerts.push({
      titulo: "Selecione o grupo!",
      botoes,
    });
  }

  addCampoValido(): any {
    this.setAviso("addcampo");
    let tp = this.ultimoTP();
    if (!tp) return false;
    if (this.campos.length < tp.campos_max) this.addCampo();
  }
  _geraCampoPremio(tp: any) {
    let premios = [];
    this.btns_premio_fixo = tp.botoes_premio
      .split(";")
      .filter((v: any) => v)
      .map((v: any) => v.split("-").join(" ao "));

    for (let i = tp.premio_ini; i <= tp.premio_fim; i++) {
      premios.push({
        id: i,
        status: false,
      });
    }

    if (tp.premio_fixo) {
      if (tp.premio_fixo.search("-") != -1) {
        let p = tp.premio_fixo.split("-");
        for (let i = p[0]; i <= p[1]; i++) {
          premios[i - 1].status = true;
        }
      } else {
        let p = tp.premio_fixo.split(";");
        for (let i of p) {
          premios[i - 1].status = true;
        }
      }
    }
    return premios;
  }
  geraCampoPremio(): any {
    let tp = this.ultimoTP();
    if (!tp) return false;
    this.premios = this._geraCampoPremio(tp);
    if (this.template.getSite().title == 'Red Lottery'){
      this.premios = [
        {
          premio: 1, value: false
        }
      ]
    }
  }

  dezenaToGrupo(dz: any) {
    dz = dz || 100;
    return Number(((dz + 1) / 4).toFixed(0));
  }

  zeroEsquerda(zeros: any, text: any) {
    text = text + "";
    let resp = "";
    let size = zeros - text.length;
    for (let i = 0; i < size; i++) {
      resp += "0";
    }
    resp += text;
    return resp;
  }

  numerosAleatorios(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  supresinha(): any {
    let tp = this.ultimoTP();
    if (!tp) return false;
    let array = [];
    if (tp.tipo == "C") {
      for (let btn of this.botoes_check) {
        btn.status = false;
      }
      this.preparaCampos(tp);
    }
    while (array.length < this.campos.length) {
      let value = this.zeroEsquerda(
        tp.caracter_min,
        this.numerosAleatorios(tp.range_ini, tp.range_fim)
      );
      if (tp.tipo == "C") {
        if (array.filter((v) => v.value == value).length) continue;
        array.push({ value });
      } else {
        array.push({ value });
      }
      for (let btn of this.botoes_check) {
        if (btn.numero == value) btn.status = true;
      }
    }
    this.campos = array;
  }

  repetirJogo(): any {
    let ultimo = this.jogos_feitos[this.jogos_feitos.length - 1];
    if (!ultimo) return false;
    let tp = this.ultimoTP();
    let tp_ultimo = ultimo.tipos[ultimo.tipos.length - 1];
    let campos = ultimo.campos;
    if (tp.tipo != tp_ultimo.tipo) return false;
    this.resetCampos();
    if (campos.length < tp.campos_min) return false;
    for (let i = 0; i < tp.campos_max; i++) {
      if (!campos[i]) continue;
      let valor = campos[i].value.substr(-tp.caracter_max);
      if (tp.tipo == "S" && tp_ultimo.tipo == "I") {
        valor = this.dezenaToGrupo(Number(valor));
      }
      this.addCampo();
      this.campos[i].value = valor;
    }
  }

  validaJogo() {
    try {
      let tp = this.ultimoTP();
      if (tp.tipo == "I" || tp.tipo == "S") {
        if (this.campos.length > tp.campos_max) return false;
        if (this.campos.length < tp.campos_min) return false;
        for (let campo of this.campos) {
          if (campo.value.length > tp.caracter_max) return false;
          if (campo.value.length < tp.caracter_min) return false;
        }
      }
      if (!tp.premio_fixo.length) {
        let pmarcados = this.premios.filter((v: any) => v.value).length;
        if (pmarcados > tp.req_premio_max) return false;
        if (pmarcados < tp.req_premio_min) return false;
      }

      if (tp.tipo == "C") {
        let cmarcados = this.getCamposAtivos().length;
        if (cmarcados > tp.campos_max) return false;
        if (cmarcados < tp.campos_min) return false;
      }
      if (tp.inicia_jogos.length) {
        let is = true;
        for (let valor of this.valores) {
          if (valor.value > 0 && valor.value < valor.tp.valor_max_aposta)
            is = false;
          if (valor.value > valor.tp.valor_max_aposta) return false;
        }
        if (is) return false;
      } else {
        if (this.valor_jogo <= 0) return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  opcoesJogo(i: any) {
    let botoes = [
      {
        titulo: `Remover jogo`,
        click: () => {
          this.jogos_feitos.splice(i, 1);
        },
      },
    ];
    botoes = [...this.conversoesJogo(this.jogos_feitos[i]), ...botoes];
    this.alerts.push({
      titulo: "Opções",
      sair: true,
      botoes,
    });
  }

  conversoesJogo(jg: any) {
    let tp = jg.tipos[0];
    if (tp.id != "1") return [];
    let botoes = [
      {
        titulo: `DDZ`,
        click: () => {
          let input = {
            value: "",
          };
          let botoes = [
            {
              titulo: `GERAR!`,
              click: () => {
                if (input.value) {
                  for (let campo of jg.campos) {
                    let _campos = [
                      { value: campo.value.substr(0, 2) },
                      { value: campo.value.substr(-2) },
                    ];
                    let ddz = this.tipojogos[7];
                    let premios = this._geraCampoPremio(ddz);
                    let jogo = {
                      tipos: [ddz],
                      campos: _campos,
                      premios_label: this._premioLabel(premios),
                      premios,
                      valor: input.value,
                    };
                    this.jogos_feitos.push(this.clone(jogo));
                  }
                }
              },
            },
          ];
          this.alerts.push({
            titulo: "Digite o valor",
            input,
            sair: true,
            botoes,
          });
        },
      },
    ];

    if (jg.campos.length > 1)
      botoes.push({
        titulo: `DDZ COMB`,
        click: () => {
          let input = {
            value: "",
          };
          let botoes = [
            {
              titulo: `GERAR!`,
              click: () => {
                if (input.value) {
                  let campos = [];
                  for (let campo of jg.campos) {
                    campos.push({ value: campo.value.substr(0, 2) });
                    campos.push({ value: campo.value.substr(-2) });
                  }
                  let ddz = this.tipojogos[7];
                  let ddzcomb = this.tipojogos[1033];
                  let premios = this._geraCampoPremio(ddzcomb);
                  let jogo = {
                    tipos: [ddz, ddzcomb],
                    campos,
                    premios_label: this._premioLabel(premios),
                    premios,
                    valor: input.value,
                  };
                  this.jogos_feitos.push(this.clone(jogo));
                }
              },
            },
          ];
          this.alerts.push({
            titulo: "Digite o valor",
            input,
            sair: true,
            botoes,
          });
        },
      });
    return botoes;
  }

  removeCampo(i: any) {
    try {
      let tp = this.ultimoTP();
      if (this.campos.length > tp.campos_min) this.campos.splice(i, 1);
    } catch (error) {}
  }

  resetCampos() {
    this.campos = [];
  }

  ultimoTP() {
    try {
      return this.tp_selecionados[this.tp_selecionados.length - 1];
    } catch (error) {
      return null;
    }
  }

  getMaskInput() {
    try {
      let tp = this.ultimoTP();
      let mask = "";
      for (let x = 0; x < tp.caracter_max; x++) {
        mask += "9";
      }
      return mask;
    } catch (error) {
      return "";
    }
  }

  getCamposAtivos() {
    return this.campos.filter((v: any) => v.status);
  }

  isAviso(chave: any) {
    return !localStorage.getItem(chave) ? 1 : 0;
  }

  setAviso(chave: any) {
    localStorage.setItem(chave, "1");
  }

  tiposSeleciodados() {
    return this.tp_selecionados.map((v: any) => v.sigla);
  }

  resetTPSelecionados() {
    this.jogo_pai = 0;
    this.tp_selecionados = [];
  }

  stepTP(): any {
    if (this.extracao == null) return false;
    this.resetTPSelecionados();
    this.step = 2;
  }

  stepOne() {
    this.reset2();
    this.step = 1;
  }

  preparaCampos(tp: any) {
    this.campos = [];
    for (let i = 0; i < tp.campos_min; i++) {
      this.addCampo();
    }
  }

  preparaTemplate() {
    try {
      let tp = this.ultimoTP();
      this.campos = [];
      if (tp.tipo == "C") {
        for (let x = tp.range_ini; x <= tp.range_fim; x++) {
          this.campos.push({
            status: false,
            value: ("0000000" + x).substr(-tp.caracter_max),
          });
        }
      } else {
        for (let x = 0; x < tp.campos_min; x++) {
          this.campos.push({
            status: true,
            value: "",
          });
        }
      }
      this.premios = [];
      for (let x = 0; x < tp.premio_fim; x++) {
        this.premios.push({
          premio: x + 1,
          value: false,
        });
      }
      this.valores = [];
      if (tp.inicia_jogos.length) {
        for (let i of tp.inicia_jogos) {
          this.valores.push({
            tp: this.tipojogos[i],
            value: 0,
          });
        }
      }
      if (this.template.getSite().title == 'Red Lottery'){
        this.premios = [
          {
            premio: 1, value: false
          }
        ]
      }
      this.valor_jogo = 0;
      this.step = 3;
    } catch (error) {
      console.log(error)
    }
  }

  geraBotoesCheck() {
    if (this.ultimoTP().tipo == "C") {
      this.campos = [];
      this.botoes_check = [];
      let ini = this.ultimoTP().range_ini;
      let fim = this.ultimoTP().range_fim;
      for (let i = ini; i <= fim; i++) {
        this.botoes_check.push({
          numero: this.zeroEsquerda(2, i),
          status: false,
        });
      }
    }
  }

  clickFormCheck(item: any): any {
    try {
      let tp = this.ultimoTP();
      if (item.status) item.status = false;
      else {
        if (this.getCamposAtivos().length >= tp.campos_max) return false;
        else item.status = true;
      }
    } catch (error) {
      console.log(error)
    }
  }

  stepFormulario() {
    let tp = this.ultimoTP();
    if (!tp) return false;
    if (tp.sigla == "BOL") {
      this.valor_jogo = this.bolo.valor;
      this.disable_input = true;
    }
    
    this.step = 3;
    return true;
  }

  // getBtnsPremios(){
  //   console.log(this.premios)
  //   if (this.template.getSite().title == 'Red Lottery') {
  //     this.premios = [
  //       {
  //         premio: 1,
  //         value: false
  //       }
  //     ]
  //   }
  //   return this.premios
  // }

  salvaJogo() {
    try {
      let tp = this.ultimoTP();
      let premios = [];
      let campos = [];

      if (tp.premio_fixo.length) {
        premios = tp.premio_fixo;
      } else {
        premios = this.premios
          .filter((v: any) => v.value)
          .map((v: any) => v.premio);
      }

      if (tp.tipo == "I" || tp.tipo == "S") {
        campos = this.campos
          .filter((v: any) => v.value)
          .map((v: any) => v.value);
      } else {
        campos = this.campos
          .filter((v: any) => v.status)
          .map((v: any) => v.value);
      }
      if (tp.inicia_jogos.length) {
        for (let valor of this.valores) {
          let jogo = {
            tps: [valor.tp],
            valor: valor.value,
            premios,
            campos,
          };
          this.jogos_feitos.push(this.clone(jogo));
        }
      } else {
        let jogo = {
          tps: this.tp_selecionados,
          valor: this.valor_jogo,
          premios,
          campos,
        };
        this.jogos_feitos.push(this.clone(jogo));
      }
      this.tp_selecionados = [];
      this.step = 2;
    } catch (error) {
      throw error
    }
  }

  getDataAposta() {
    try {
      return this.data.data.split("-").reverse().join("/");
    } catch (error) {
      return "-------";
    }
  }

  totalAposta() {
    let total = 0;
    for (let j of this.jogos_feitos) {
      total += j.valor;
    }
    return total;
  }

  _premioLabel(premios: any) {
    let array = premios.filter((p: any) => p.status).map((p: any) => p.id);
    let label = "";
    let ultimo = null;
    array.map((p: any, i: any) => {
      let ant = Number(array[i - 1]);
      let prx = Number(array[i + 1]);
      p = Number(p);
      if (!ant) {
        label += p;
        return true;
      }
      if (p - 1 == ant && prx != p + 1) {
        label += "a" + p;
        return true;
      }
      if (p - 1 == ant && p + 1 == prx) {
        return true;
      }
      if (prx == p + 1 && ant != p - 1) {
        label += "," + p;
        return true;
      }
      if (prx != p + 1 && ant != p - 1) {
        label += "," + p;
        return true;
      }
      if (!prx && ant != p - 1) {
        label += "," + p;
        return true;
      } else return "";
    });
    return label;
  }
  premioLabel() {
    return this._premioLabel(this.premios);
  }

  formataDados() {
    let jogos_feitos = this.jogos_feitos;
    let retorno: any = {
      cotada: false,
      data: this.data.data,
      horario: [this.data.extracoes[this.extracao].extracao],
      os: 99,
      versao: 3,
      posicao: this.posicao,
      jogos: [],
      token_logado: false,
      palpitin: true,
    };
    for (let i = 0; i < jogos_feitos.length; i++) {
      let dados = [];
      let ids = [];
      let premios = [];
      let siglas = [];
      for (let index = 0; index < jogos_feitos[i].campos.length; index++) {
        dados.push(jogos_feitos[i].campos[index].value);
      }
      for (let index = 0; index < jogos_feitos[i].premios.length; index++) {
        if (jogos_feitos[i].premios[index].status)
          premios.push(jogos_feitos[i].premios[index].id);
      }
      for (let index = 0; index < jogos_feitos[i].tipos.length; index++) {
        ids.push(jogos_feitos[i].tipos[index].id);
        siglas.push(jogos_feitos[i].tipos[index].sigla);
      }
      retorno.jogos.push({
        dados: dados,
        ids: ids,
        siglas: siglas,
        premios: premios,
        valor: jogos_feitos[i].valor,
      });
    }
    return retorno;
  }

  reset() {
    this.jogos_feitos = [];
    this.tp_selecionados = [];
    this.campos = [];
    this.premios = [];
    this.valor_jogo = 0;
    this.step = 1;
  }
  getSiglasJogoFeiro(item: any) {
    try {
      return item.tps.map((v: any) => v.sigla).join(" ");
    } catch (error) {
      return "";
    }
  }
  reset2() {
    this.jogos_feitos = [];
    this.tp_selecionados = [];
    this.campos = [];
    this.premios = [];
    this.valor_jogo = 0;
    this.libera_pagamento = false;
    this.resumo_pule = {
      show: false,
      tipo_aposta: 0,
      dados: { cod: [], criado: "" },
    };
    this.opcoes_finalizar = false;
    this.opcoes_credito = false;
    this.scan_qr = false;
    this.disable_input = false;
    setTimeout(() => {
      this.erro = { show: false, msg: "" };
    }, 2000);
  }

  async get_sorteio(data: any) {
    this.loading_resultado = true;
    try {
      let resp = await this.server.post(`/jb/post`, {
        rota: "sorteios",
        dados: { data: data },
      });
      this.loading_resultado = false;
      if (resp.length) {
        this.sorteios = resp;
      } else {
        this.sorteios = [];
      }
      return resp;
    } catch (error) {
      console.log(error);
      this.loading_resultado = false;
    }
  }

  mostra_pule() {
    this.show_pule = !this.show_pule;
    this.show_resultado = false;
    if (this.show_pule) {
      this.show_corpo = false;
    } else {
      this.show_corpo = true;
    }
  }

  mostra_resultado() {
    this.show_pule = false;
    this.show_resultado = !this.show_resultado;
    if (this.show_resultado) {
      this.show_corpo = false;
    } else {
      this.show_corpo = true;
    }
  }

  set_extracao(extracao: any, i: any) {
    this.extracao = i;
    this.extracao_ativa = extracao.extracao;
  }

  atualiza_ranking() {
    this.ranking_bolo = { loading: true, dados: null, status: false };
    this.server
      .post("/jb/post", {
        rota: "ranking_bolo",
        dados: {},
      })
      .then((dados) => {
        let bolos = dados.ranking;
        for (let i = 0; i < bolos.length; i++) {
          bolos[i].linha1 = [];
          bolos[i].linha2 = [];
          let dezenas = bolos[i].dezenas;
          for (let j = 0; j < dezenas.length; j++) {
            if (j <= 4) {
              bolos[i].linha1.push(bolos[i].dezenas[j]);
            }
            if (j > 4) {
              bolos[i].linha2.push(bolos[i].dezenas[j]);
            }
          }
        }
        this.ranking_bolo = { loading: false, dados: dados, status: true };
      })
      .catch((e) => {
        console.log(e);
        this.ranking_bolo = { loading: false, dados: null, status: false };
      });
  }

  premioLabel2(array: any) {
    let label = "";
    let ultimo = null;
    array.map((p: any, i: any) => {
      let ant = Number(array[i - 1]);
      let prx = Number(array[i + 1]);
      p = Number(p);
      if (!ant) {
        label += p;
        return true;
      }
      if (p - 1 == ant && prx != p + 1) {
        label += "a" + p;
        return true;
      }
      if (p - 1 == ant && p + 1 == prx) {
        return true;
      }
      if (prx == p + 1 && ant != p - 1) {
        label += "," + p;
        return true;
      }
      if (prx != p + 1 && ant != p - 1) {
        label += "," + p;
        return true;
      }
      if (!prx && ant != p - 1) {
        label += "," + p;
        return true;
      } else return "";
    });
    return label;
  }
}
