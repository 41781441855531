<header class="headerPrincipal" *ngIf="show">
  <menu></menu>
</header>
<div class="row col-md-12 bodyPrincipal" *ngIf="!isJB && show">
  <div class="col-md-2 p-0 menuLeft">
    <app-left-web-menu></app-left-web-menu>
  </div>
  <div class="col-md-7 p-0 contentInicio">
    <router-outlet onActivate="onActivate($event)"></router-outlet>
  </div>
  <div class="col-md-3 p-0" *ngIf="template.getSite().title != 'ESPORTE SUL'">
    <app-finaliza-mobile class="finalizaMobile"></app-finaliza-mobile>
    <app-finaliza-jogo class="finalizaWeb"></app-finaliza-jogo>
  </div>
  <div class="col-md-3 p-0 body-resumo" *ngIf="template.getSite().title == 'ESPORTE SUL'">
    <app-finaliza-mobile class="finalizaMobile"></app-finaliza-mobile>
    <app-finaliza-jogo class="finalizaWeb"></app-finaliza-jogo>
  </div>
</div>
<div *ngIf="isJB && show">
  <app-jb></app-jb>
</div>
<div *ngIf="!show">
  <app-politicas-privacidade></app-politicas-privacidade>
</div>
<app-footer-template class="appFooter"></app-footer-template>
