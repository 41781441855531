import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import * as dayjs from "dayjs";
import { UtilService } from "src/app/service/util.service";
import { TemplateService } from "src/app/service/template.service";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.scss"],
})
export class InicioComponent implements OnInit {
  @ViewChild("item") item!: any;
  @HostListener("scroll", ["$event"])
  async onScroll(event: any) {
    if (!this.api.loading && (this.api.campeonatos?.length % 10 == 0 || this.api.campeonatos?.length == 10)){
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight && this.api.campeonatos.length >= 10
      ) {
        this.api.page++;
        await this.api.getCampeonatos({ page: this.api.page});
      }
    }
  }
  mostraMaisCotacoes: any = false;
  pagina: any = 0;
  ligas: any;
  loading = false;
  form: FormGroup;
  days: any = [];
  dayIndex: any = null;
  public jogosApostados: any = [];
  campeonatos: any;
  day: any = "";
  maisDatas = false;
  // dayjs().locale('br')
  constructor(public api: ApiService, private fb: FormBuilder, public util: UtilService, public template: TemplateService) {
    this.form = this.fb.group({
      busca: this.fb.control(""),
    });
  }

  async ngOnInit() {
    await this.api.updateConfigFutebol()
    this.daysResult()
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    setTimeout(() => {
      window.location.reload()
    }, 600000)
  }


  get1x2(cotacoes:any){
    let casa = cotacoes.find((c:any) => c.id == 1)
    let empt = cotacoes.find((c:any) => c.id == 2)
    let fora = cotacoes.find((c:any) => c.id == 3)
    if (!casa) casa = {
      ao_vivo: true,
      id: 1,
      sigla: ['PRINCIPAIS', '1X2', 'CASA'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    if (!empt) empt = {
      ao_vivo: true,
      id: 2,
      sigla: ['PRINCIPAIS', '1X2', 'EMPATE'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    if (!fora) fora = {
      ao_vivo: true,
      id: 3,
      sigla: ['PRINCIPAIS', '1X2', 'FORA'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    return [casa, empt, fora]
  }

  trocaValor(valor: any){
    if(valor == 1) this.maisDatas = !this.maisDatas
  }

  async getMaisMobile(){
    this.api.page++
    await this.api.getCampeonatos({ page: this.api.page})
  }


  daysResult() {
    let limitDays = 6
    if (this.template.getSite().matriz == "5e29cdc3590ed80012d68170"){
      limitDays = 3
    }
    for (let day = 0; day < limitDays; day++) {
      if (dayjs().add(day, "day").day() == 1 && dayjs().day() != 1) return 1
      this.days.push(dayjs().add(day, "day").toISOString());
    }
    return 1
  }

  
  async escolheData(data: any){
    this.day = data
    await this.api.getCampeonatos({data: dayjs(data).format('YYYY-MM-DD'), reset: 3})
  }

  async todasDatas(){
    this.day = null
    if(this.maisDatas){this.api.getCampeonatos({reset: 2})}
  }

  async maisCotacoes(jogo: any) {
    this.api.loading = true;
    try {
      await this.api.getAllCotacoes(jogo);
      setTimeout(() => {
        this.mostraMaisCotacoes = true;
        this.api.mostraMaisCotacoes = true
      }, 300);
      this.api.scrollTop()
    } catch (error) {
      console.log(error);
    }
    this.api.loading = false
  }


  async refresh(suspend = false, ){
   this.api.loading_aovivo = true
   await this.util.sleep(2000)
   if (suspend){
     let random_booleam = (Math.floor(Math.random() * 2) == 0) ? false : true
     this.api.campeonatos[0].jogos[Math.floor(Math.random() * 2)].cotacoes[Math.floor(Math.random() * 3)].suspended = random_booleam
   }
   this.api.loading_aovivo = false
  }
}
