import { UtilService } from './util.service';
import { NotificacaoService } from "./notificacao.service";
import { TemplateService } from "./template.service";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import * as faker from "faker";
import * as dayjs from "dayjs";
import * as QRCode from "qrcode";
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public regras = {
    leu: false,
    mostraModal: false
  }
  time_loops = {}
  loading_aovivo: boolean = false
  countrys: any = [];
  campeonatos: any = [];
  campeonatos_aovivo: any = [];
  campeonatoSelecionado: any;
  jogosApostados: any = [];
  cotacoesJogoSelecionado: any = {};
  emMaisCotacoes: any;
  resultados: any = [];
  servidor: any = null;
  servidor_io: any = "http://localhost:3001"
  public cod_busca: any = "";
  loading = false;
  aposta_busca: any = null;
  erroBusca = false;
  showPuleBusca = false;
  creditos: any = [];
  nome_cliente: any = "";
  telefone_cliente: any = "";
  libera_odds = 0;
  valor_minimo = 1;
  valor_aposta = 0;
  transmitindo = false;
  credito_selecionado: any = null;
  apostaRecebida: any = null;
  apostasUsuario: any = null;
  inputAddCredito: any = "";
  inputBuscaVenda: any = "";
  premio_max: any = 20000;
  enableBusca = false;
  data: any = "";
  page: any = 0;
  campeonato: any = "";
  busca: any = "";
  mostraMaisCotacoes = false
  isJb = false
  partidas_aovivo = 0
  showAoVivo = false
  vendas:any = []
  premios:any = []
  public sorteios: any = []
  public dataBusca: any

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificacao: NotificacaoService,
    private template: TemplateService,
    private util: UtilService,
    private socket: Socket
  ) {

    this.servidor = "https://palpitin2api.appspot.com";
    this.servidor_io = ""
    // this.servidor = "http://localhost:3000";
    // this.servidor_io = "https://fut-api-dot-palpitin2api.rj.r.appspot.com"

    if (this.template.getSite().title == "Estadium"){
     try {
      // this.socket.on("liveFixt", async (data: any) => {
      //   this.loading_aovivo = true
      //   console.log("RECEBENDO PARTIDAS AO VIVO")
      //   this.campeonatos_aovivo = data.lista
      //   this.campeonatos_aovivo.sort((a: any, b: any) => (a.descricao > b.descricao ? 1 : -1))
      //   // console.log(this.campeonatos_aovivo)
      //   for (let camp of this.campeonatos_aovivo) {
      //     for (let jogo of camp.jogos) {
      //       jogo.principais = this.getPrincipais(jogo)
      //     }
      //   }
      //   this.partidas_aovivo = data.qtd
      //   await this.util.sleep(1000)
      //   this.loading_aovivo = false
      // })
     } catch (error) {
       console.log(error)
     }
    }
  }

  getPrincipais(jogo: any){
    let cotacoes = jogo.cotacoes
    let casa = cotacoes.find((c:any) => c.id == 1)
    let empt = cotacoes.find((c:any) => c.id == 2)
    let fora = cotacoes.find((c:any) => c.id == 3)
    if (!casa) casa = {
      _id: `${jogo._id}1`,
      ao_vivo: true,
      id: 1,
      sigla: ['PRINCIPAIS', '1X2', 'CASA'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    if (!empt) empt = {
      _id: `${jogo._id}2`,
      ao_vivo: true,
      id: 2,
      sigla: ['PRINCIPAIS', '1X2', 'EMPATE'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    if (!fora) fora = {
      _id: `${jogo._id}3`,
      ao_vivo: true,
      id: 3,
      sigla: ['PRINCIPAIS', '1X2', 'FORA'],
      suspended: true,
      valor: 1,
      valorb: 1
    }
    return [casa, empt, fora]
  }


  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  getMessage() {
    return this.socket.fromEvent('message').pipe(map((data: any) => data.msg));
  }


  showCampeonatos(){
    if (this.showAoVivo) return this.campeonatos_aovivo
    else return this.campeonatos
  }


  loopMinutesPartida(partida: any) {
    let dataAtual = dayjs()
    let _inicio = dayjs(partida.data_hora)
    let segundos = dataAtual.diff(_inicio, 'second')
    let minutos = Math.floor(segundos / 60);
    segundos = segundos - (minutos * 60);
    let tempo = "1º T"
    if (minutos > 45) tempo = "2º T"
    let showMinutos = minutos
    let showSegundos = segundos
    if (minutos >= 45 && minutos <= 55) {
      showMinutos = 45
      showSegundos = 0
    }
    if (minutos > 90){
      minutos = 90
      showMinutos = 90
      showSegundos = 0
    }
    partida.label_time = `${tempo} ${("00" + String(showMinutos)).substr(-2)}:${("00" + String(showSegundos)).substr(-2)}`
    console.log(`ROLANDO A ${minutos} MINUTOS`)
    if (minutos < 90) setTimeout(() => { this.loopMinutesPartida(partida) }, 1000)
    return minutos
  }

  async getJogosAovivo() {
    this.loading = true
    this.campeonatos = []
    try {
      // this.socket.on("liveFixt", (data: any) => {
      //   console.log("RECEBENDO PARTIDAS AO VIVO")
      //   if (this.showAoVivo){
      //     this.campeonatos = data.lista
      //     this.loading_aovivo = true
      //     this.campeonatos.sort((a: any, b: any) => (a.descricao > b.descricao ? 1 : -1))
      //   }
      //   for (let camp of this.campeonatos) {
      //     for (let jogo of camp.jogos) {
      //       if (this.showAoVivo) this.loopMinutesPartida(jogo)
      //     }
      //   }
      //   this.partidas_aovivo = data.qtd
      //   this.loading_aovivo = false
      // })
    } catch (error) {
      console.log(error)
      throw error
    }
    this.loading = false
  }

  async getSorteios() {
    this.loading = true
    this.campeonatos = []
    try {
      this.sorteios = await this.post(`/site/sorteios`, { empresa: this.template.getSite().matriz, data: this.dataBusca.split("T")[0] });
      console.log(this.sorteios)
    } catch (error) {
      console.log(error)
      this.sorteios = []
    }
    this.loading = false
  }

  async addCreditoConta() {
    if (this.inputAddCredito.length == 8) {
      this.loading = true;
      try {
        let credito = this.inputAddCredito;
        let data = await this.post(`/site/cliente/addcreadito`, { credito });
        await this.meusCreditos();
      } catch (error) {
        console.log(error);
      }
      this.inputAddCredito = "";
      this.loading = false;
    }
  }

  getPremioFormatado() {
    return this.util.floatToMoney(this.getPremio())
  }

  async updateConfigFutebol() {
    this.loading = true;
    try {
      let data = await this.get(
        `/site/getconfig/${this.template.getSite().matriz}`
      );
      if (data && data.futebol.maximo_premio)
        this.premio_max = Number(data.futebol.maximo_premio);
      if (data.futebol.minimo_aposta)
        this.valor_minimo = Number(data.futebol.minimo_aposta);
      if (this.template.getSite().matriz == "600f0cbef6a4c8000a2799fd") {
        this.valor_minimo = 5;
        this.libera_odds = 3;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  getPremio() {
    let premio = this.valor_aposta * this.valores();
    if (premio > this.premio_max) premio = this.premio_max;
    return premio;
  }

  getToken() {
    let usuarioLogado = this.getSessao();
    if (usuarioLogado) return usuarioLogado.token;
    else return "";
  }

  httpOptions() {
    return {
      headers: new HttpHeaders({
        token: this.getToken(),
        "Content-Type": "application/json",
        matriz: this.template.getSite().matriz,
      }),
    };
  }

  errorRest(error: any) {
    this.notificacao.erroRest(error);
  }

  async finalizaAposta(type: any) {
    // if (this.template.getSite().inserir_nome_cliente){
    //   if (this.nome_cliente.length == 0){
    //     alert("Insira seu nome antes de finalizar.")
    //     return 1
    //   }
    // }
    // if (this.libera_odds > 0) {
    //   let cots = this.jogos.map(jogo => this.getCot(jogo.cotacoes))
    //   for (let cot of cots){
    //     if (this.jogos.length <= this.libera_odds) {
    //       if (cot.id > 3){
    //         alert(`Combinadas de até ${this.libera_odds} só podem ser jogados nas modalidades 1X2.`)
    //         return 1
    //       }
    //     }
    //   }
    // }
    if (this.valor_aposta < this.valor_minimo) {
      alert(`Aposta abaixo do valor minimo (${this.valor_minimo}).`);
      return 1;
    }
    if (this.transmitindo) return false;
    this.transmitindo = true;
    this.loading = true;
    try {
      let hash_numero = false;
      // if (this.template.getSite().hash_numero) hash_numero = true
      let ids = this.jogosApostados.map((jogo: any) => String(jogo.cotacoes._id));
      let cotacoes =  this.jogosApostados.map((jogo: any) => ({_id: String(jogo.cotacoes._id), valor: jogo.cotacoes.valor }));
      let valor = this.valor_aposta;
      // if (this.template.getSite().estadium) {
      //   await this.finalizaEstadium(valor, ids, this.template.getSite().matriz)
      //   return 1
      // }
      let credito: any = null;
      if (this.credito_selecionado)
        credito = this.creditos.find((c: any) => c._id == this.credito_selecionado);
      let idcredito = null;
      let link = "/site/aposta";
      if (type == 3) {
        if (!this.credito_selecionado) {
          alert("Selecione um crédito antes de finalizar.");
          this.transmitindo = false;
          this.loading = false;
          return 1;
        }
        idcredito = credito._id;
        link = "/site/cliente/aposta";
      }
      if (this.getSessao()) link = "/site/cliente/aposta";
      if (credito) credito = credito.token;
      let post: any = {
        ids,
        valor,
        credito: "",
        idcredito,
        hash_numero,
        matriz: this.template.getSite().matriz,
        nome_cliente: this.nome_cliente,
        telefone_cliente: this.telefone_cliente
      }
      if (this.template.getSite().estadium) {
        link = `/stadium/futebol/apostar/publica`
        if (this.getSessao()?.ponto) {
          link = `/stadium/futebol/apostarv2`
          post = {
            valor,
            cotacoes,
            posicao: { lat: null, long: null },
            site_matriz: this.template.getSite().matriz,
            nome: this.nome_cliente,
            telefone: this.telefone_cliente,
            id_cliente: null
          }
        } else {
          post = {
            valor,
            cotacoes: ids,
            posicao: { lat: null, long: null },
            site_matriz: this.template.getSite().matriz,
            nome_cliente: this.nome_cliente,
            telefone_cliente: this.telefone_cliente
          }
        }
      }
      let data = await this.post(link, post);
      if (this.template.getSite().estadium) data = data.aposta
      if (data.status != 1) {
        data.qr = await this.generateQRCode(data.cod_validacao);
      }
      let site = `beta`
      if (this.template.getSite().titulo == "ESPORTE SUL") site = "esportesul"
      data.link = `https://wa.me/?text=Consulte%20sua%20aposta%20em:%20https://${site}.estadium.in/inicio?codBusca=${data.cod}`
      data.link_consulta = `https://${site}.estadium.in/inicio?codBusca=${data.cod}`
      this.apostaRecebida = data;
      this.jogosApostados = [];
      this.nome_cliente = "";
      this.telefone_cliente = "";
      this.credito_selecionado = null;
      // this.campeonatos.map(c => c.jogos.map(j => j.cotacoes.map(c => c.check = false)));
      this.valor_aposta = 0;
      if (this.template.getSite().showRegas && this.regras.leu) this.regras.leu = false
    } catch (error) {
      console.log(error);
    }
    this.transmitindo = false;
    this.loading = false;
    return 1;
  }

  async meusCreditos() {
    this.loading = true;
    try {
      let data = await this.get(`/site/cliente/creditos`);
      this.creditos = data;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async getBilhetes() {
    this.loading = true;
    try {
      let apostas = await this.get(`/site/cliente/getapostas`);
      this.apostasUsuario = apostas;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async getHistoricoCredito(dados: any) {
    return await this.post(`/site/credito/historico`, dados);
  }

  getSessao() {
    let text: any = localStorage.getItem("usuario");
    return JSON.parse(text);
  }

  getTextDateTimeUsToBR(data = new Date()) {
    let hora = data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
    let min =
      data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();
    let seg =
      data.getSeconds() < 10 ? "0" + data.getSeconds() : data.getSeconds();
    let dia = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
    let mes =
      data.getMonth() + 1 < 10
        ? "0" + (data.getMonth() + 1)
        : data.getMonth() + 1;
    let ano = data.getFullYear();
    return `${dia}/${mes}/${ano} ${hora}:${min}:${seg}`;
  }

  async getAposta() {
    this.loading = true;
    this.erroBusca = false;
    try {
      let data: any = await this.http
        .get(`${this.servidor}/site/aposta/get/${this.cod_busca}`)
        .toPromise();
      this.loading = false;
      if (data.length) {
        this.aposta_busca = data[0];
        this.aposta_busca.data_hora_string = this.getTextDateTimeUsToBR(
          new Date(this.aposta_busca.createdAt)
        );
        this.showPuleBusca = true;
      } else {
        this.erroBusca = true;
        this.aposta_busca = null;
        this.cod_busca = "";
      }
    } catch (error) {
      this.loading = false;
      this.showPuleBusca = false;
      this.erroBusca = false;
      console.log(error);
    }
  }

  async login(values: any) {
    try {
      return await this.post(`/site/login`, values);
    } catch (error) {
      console.log(error);
    }
  }

  logoff() {
    localStorage.clear();
    this.apostasUsuario = null;
    this.router.navigate(["/inicio"]);
  }

  setLogado(data: any) {
    localStorage.setItem("usuario", JSON.stringify(data));
  }

  // async getCampeonatos({ page = 0, busca = "", data = "", campeonato = "" }) {
  //   if (!busca && !campeonato) {
  //     let campeonatos: any = await this.http
  //       .get(
  //         `${this.servidor}/site/eventos/${page}/${busca}/${data}/${campeonato}`
  //       )
  //       .toPromise();
  //     for (let campeonato of campeonatos) {
  //       this.campeonatos.push(campeonato);
  //     }
  //   } else {
  //     this.campeonatos = await this.http
  //       .get(
  //         `${this.servidor}/site/eventos/${page}/${busca}/${data}/${campeonato}`
  //       )
  //       .toPromise();
  //   }
  //   console.log(this.campeonatos)
  // }

  // async getCampeonatos({ page = 0, busca = "", data = "", campeonato = "" }) {
  //   if (!busca && !campeonato) {
  //     let campeonatos: any = await this.get(
  //       `/site/eventos/${page}/${busca}/${data}/${campeonato}`
  //     );
  //     if (!campeonatos.length) {
  //       this.enableBusca = false;
  //       return 1;
  //     }
  //     for (let campeonato of campeonatos) {
  //       if (!this.campeonatos.find((c) => c._id == campeonato._id)) {
  //         this.campeonatos.push(campeonato);
  //       }
  //     }
  //     // console.log("@@@@@@@@@@")
  //     // console.log(campeonatos)
  //   } else {
  //     let campeonatos = await this.get(
  //       `/site/eventos/${page}/${busca}/${data}/${campeonato}`
  //     );
  //     if (!campeonatos.length) {
  //       this.enableBusca = false;
  //       return 1;
  //     }
  //     this.campeonatos = campeonatos;
  //     // console.log("@@@@@@@@@@11111111111")
  //   }
  //   return 1;
  // }
  async getCampeonatos({
    page = 0,
    busca = "",
    data = "",
    campeonato = "",
    reset = 0,
  }) {
    this.loading = true
    data != "" ? this.data = data : data = this.data
    busca != "" ? this.busca = busca : busca = this.busca
    campeonato != "" ? this.campeonato = campeonato : campeonato = this.campeonato
    if (reset == 1) this.campeonatos = [];
    if (reset == 2) { this.campeonatos = [], page = 0, this.busca = "", busca = "", this.campeonato = "", campeonato = "", data = "", this.data = "" };
    if (reset == 3) { this.campeonatos = [], page = 0, this.busca = "", busca = "", campeonato = "", this.campeonato = "" };
    if (this.campeonato || (this.busca && this.campeonatos.length < 10)) page = 0
    try {
      if (page == 0) this.page = 0
      let url = `/site/eventos/${page}/${busca}/${data}/${campeonato}`
      if (this.template.getSite().estadium) {
        url = `/stadium/futebol/eventos/publica/${page}/${busca}/${data}/${campeonato}`
      }
      let campeonatos: any = await this.get(
        url
      );
      console.log("RESP =>>>")
      console.log(campeonatos)
      for (const campeonato of campeonatos) {
        this.campeonatos.push(campeonato);
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false
  }

  async getResultados(day: any) {
    this.resultados = await this.http
      .get(`${this.servidor}/site/resultados/0/${day}`)
      .toPromise();
  }

  async getVendas(dados: any) {
    return await this.post("/site/vendas_ponto", dados)
  }
  async getFechamento(inicio:any, fim:any) {
    return await this.get(`/stadium/futebol/guia/simples/${inicio}/${fim}`)
  }
  async getSaldoPonto() {
    return await this.get(`/stadium/futebol/saldo/diario/ponto/1`)
  }

  async countrysSideBar() {
    this.countrys = await this.http
      .get(`${this.servidor}/site/campeonatos`)
      .toPromise();
  }

  valores() {
    let valores = this.jogosApostados.map((j: any) => j.cotacoes.valor);
    let valor_total = 1;
    for (let valor of valores) {
      valor_total *= valor;
    }
    return valor_total;
  }

  removeAposta(id: any) {
    this.jogosApostados = this.jogosApostados.filter(
      (jogo: any) => jogo._id !== id
    );
  }

  escolheAposta(valor: any) {
    console.log(valor.cotacao)
    if (valor.cotacao.suspended) return;
    if (valor.cotacao.ao_vivo) return;
    let jogoMarcado = this.jogosApostados.findIndex(
      (jogo: any) => jogo._id == valor.jogo._id
    );
    if (
      jogoMarcado >= 0 &&
      this.jogosApostados[jogoMarcado] &&
      this.jogosApostados[jogoMarcado]._id == valor.jogo._id
    ) {
      if (this.jogosApostados[jogoMarcado].cotacoes._id === valor.cotacao._id) {
        this.jogosApostados = this.jogosApostados.filter(
          (jogo: any) => jogo._id !== valor.jogo._id
        );
      } else {
        this.jogosApostados[jogoMarcado] = {
          ...valor.jogo,
          cotacoes: valor.cotacao,
        };
      }
    } else {
      this.jogosApostados.push({
        ...valor.jogo,
        cotacoes: valor.cotacao,
      });
    }
  }

  async getAllCotacoes(valor: any) {
    let todasCotacoes: any = {}
    for (let cotacao of valor.cotacoes) {
      if (!todasCotacoes[cotacao.sigla[0]]) todasCotacoes[cotacao.sigla[0]] = { siglaMaior: cotacao.sigla[0], tipo: {} }
      if (!todasCotacoes[cotacao.sigla[0]].tipo[cotacao.sigla[1]]) todasCotacoes[cotacao.sigla[0]].tipo[cotacao.sigla[1]] = { siglaMeio: cotacao.sigla[1], jogos: [] }
      todasCotacoes[cotacao.sigla[0]].tipo[cotacao.sigla[1]].jogos.push(cotacao)
    }
    todasCotacoes = Object.keys(todasCotacoes).map(maior => todasCotacoes[maior])
    todasCotacoes = todasCotacoes.map((maior: any) => {
      maior.tipo = Object.keys(maior.tipo).map(menor => maior.tipo[menor])
      return maior
    })
    this.cotacoesJogoSelecionado.cotacoes = await todasCotacoes;
    this.cotacoesJogoSelecionado.jogo = await { ...valor };
    console.log(this.cotacoesJogoSelecionado)
  }

  cotacaoSelect(id = "") {
    let checkedes = this.jogosApostados.map((jogos: any) => jogos.cotacoes._id);
    let checked = checkedes.filter((checkedes: any) => checkedes == id);
    return checked == id ? true : false;
  }

  generateQRCode(data: any) {
    let opts: any = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 0.8,
      margin: 2,
    };
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(data, opts, (err: any, url: any) => {
        if (err) reject(err);
        resolve(url);
      });
    });
  }

  scrollTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  private async interceptor(promessa: Promise<any>) {
    try {
      let data = await promessa;
      return data;
    } catch (error: any) {
      if (error.status == 403) {
        localStorage.clear();
        this.router.navigate(["/login"]);
      }
      if (error.error)
        if (error.error.msg) {
          this.errorRest(error);
        }
      console.log(error);
      throw error;
    }
  }

  public post(url: any, data: any) {
    return this.interceptor(
      this.http.post(this.servidor + url, data, this.httpOptions()).toPromise()
    );
  }

  public async get(url: any) {
    try {
      return await this.interceptor(
        this.http.get(this.servidor + url, this.httpOptions()).toPromise()
      );
    } catch (error) {
      console.log(error);
    }
  }
}
